import React, { FC } from 'react';

import apple_pay from '../../assets/logos/apple_pay.svg';
import dankort_dark from '../../assets/logos/dankort_dark.png';
import dankort_light from '../../assets/logos/dankort_light.png';
import google_pay from '../../assets/logos/google_pay.png';
import maestro_dark from '../../assets/logos/maestro_dark.svg';
import maestro_light from '../../assets/logos/maestro_light.svg';
import mastercard from '../../assets/logos/mastercard.svg';
import mobilepay_dark from '../../assets/logos/mobilepay_dark.svg';
import mobilepay_light from '../../assets/logos/mobilepay_light.svg';
import neonomics_dark from '../../assets/logos/neonomics_dark.svg';
import neonomics_light from '../../assets/logos/neonomics_light.svg';
import riverty from '../../assets/logos/riverty.svg';
import riverty_afterpay from '../../assets/logos/riverty-afterpay.svg';
import swedbank_dark from '../../assets/logos/swedbank_dark.svg';
import swedbank_light from '../../assets/logos/swedbank_light.svg';
import trustly_dark from '../../assets/logos/trustly_dark.png';
import trustly_light from '../../assets/logos/trustly_light.png';
import vipps_dark from '../../assets/logos/vipps_dark.svg';
import vipps_light from '../../assets/logos/vipps_light.svg';
import visa_dark from '../../assets/logos/visa_dark.svg';
import visa_light from '../../assets/logos/visa_light.svg';

type PaymentOptionLogoProps = {
  logo: string;
};

export const PaymentOptionLogo: FC<PaymentOptionLogoProps> = ({ logo }) => {
  const imgClasses = 'h-full max-w-16';
  switch (logo) {
    case 'apple_pay.svg':
      return (
        <img className={imgClasses} src={apple_pay} alt="Apple Pay logo" />
      );
    case 'google_pay.png':
      return (
        <img className={imgClasses} src={google_pay} alt="Apple Pay logo" />
      );
    case 'dankort_dark.png':
      return (
        <img className={imgClasses} src={dankort_dark} alt="Dankort logo" />
      );
    case 'dankort_light.png':
      return (
        <img className={imgClasses} src={dankort_light} alt="Dankort logo" />
      );
    case 'maestro_dark.svg':
      return (
        <img className={imgClasses} src={maestro_dark} alt="Maestro logo" />
      );
    case 'maestro_light.svg':
      return (
        <img className={imgClasses} src={maestro_light} alt="Maestro logo" />
      );
    case 'mobilepay_dark.svg':
      return (
        <img className={imgClasses} src={mobilepay_dark} alt="MobilePay logo" />
      );
    case 'mobilepay_light.svg':
      return (
        <img
          className={imgClasses}
          src={mobilepay_light}
          alt="MobilePay logo"
        />
      );
    case 'neonomics_dark.svg':
      return (
        <img className={imgClasses} src={neonomics_dark} alt="Neonomics logo" />
      );
    case 'neonomics_light.svg':
      return (
        <img
          className={imgClasses}
          src={neonomics_light}
          alt="Neonomics logo"
        />
      );
    case 'riverty-afterpay.svg':
      return (
        <img
          className={imgClasses}
          src={riverty_afterpay}
          alt="Riverty Afterpay logo"
        />
      );
    case 'riverty.svg':
      return <img className={imgClasses} src={riverty} alt="Riverty logo" />;
    case 'swedbank_dark.svg':
      return <img className={imgClasses} src={swedbank_dark} alt="Swedbank logo" />;
    case 'swedbank_light.svg':
      return <img className={imgClasses} src={swedbank_light} alt="Swedbank logo" />;
    case 'vipps_dark.svg':
      return <img className={imgClasses} src={vipps_dark} alt="Vipps logo" />;
    case 'vipps_light.svg':
      return <img className={imgClasses} src={vipps_light} alt="Vipps logo" />;
    case 'mastercard.svg':
      return (
        <img className={imgClasses} src={mastercard} alt="Mastercard logo" />
      );
    case 'visa_dark.svg':
      return <img className={imgClasses} src={visa_dark} alt="Visa logo" />;
    case 'visa_light.svg':
      return <img className={imgClasses} src={visa_light} alt="Visa logo" />;
    case 'trustly_dark.png':
      return (
        <img className={imgClasses} src={trustly_dark} alt="Trustly logo" />
      );
    case 'trustly_light.png':
      return (
        <img className={imgClasses} src={trustly_light} alt="Trustly logo" />
      );
  }
  return null;
};
