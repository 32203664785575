import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/outline';
import { PhoneIcon } from '@heroicons/react/24/outline';

import { PayflowContext } from '../contexts/payflow-context';
import {
  SplitPaymentsService,
  PayflowGetResponse,
  PaymentStatus,
} from '../payflow-client';
import { Page } from '../components/Page';
import { AlertDialog } from '../components/AlertDialog';
import { TextField } from '../components/inputs/TextField';
import { BrandCard } from '../components/BrandCard';
import { BrandButton } from '../components/inputs/BrandButton';
import { PaymentDetails } from '../sections/PaymentDetails';
import { currencyFormat } from '../utils/locale-utils';
import apiErrorToText from '../utils/api-errors-parser';
import { Loader } from '../components/Loader';

/*
  Future improvements can be to add buttons to quickly splitt the payment evenlty between all people,
  or to assign the remaining amount to a specific person.
*/

// TODO: Handle redirect to the remainder payment if the original payment link is opened after the
// split payment process has been completed, and the remainder payment is unpaid.
// This should probably be handled in useRedirect.ts?

export const SplitPaymentBetweenPeoplePage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [updatedPaymentStatus, setUpdatedPaymentStatus] = useState<
    PaymentStatus | undefined
  >();
  const [apiError, setApiError] = useState<string | undefined>();
  const [formState, setFormState] = useState([
    { name: '', phone_number: '', amount: '' },
  ]);
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined);
  const [showConfirmShareBillDialog, setShowConfirmShareBillDialog] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Page
      id="split-payment"
      title={t('Split the bill')}
      queryFn={PaymentService ? PaymentService.get : null}
      pageLoader={!isSaving}
    >
      {page => {
        if (page?.data && 'payment_options' in page?.data) {
          const data = page?.data as PayflowGetResponse;
          const paidSoFar =
            data?.split_payment?.split
              ?.filter((split: any) => split.status == PaymentStatus.PAID)
              .reduce((acc: number, split: any) => acc + split.total, 0) || 0;
          const totalAmount = data?.payment?.gross_amount || 0;
          const isValidForm = formState.every(item => {
            const amount = !isNaN(parseFloat(item.amount))
              ? parseFloat(item.amount) * 100
              : undefined;

            return (
              item.name !== '' &&
              item.phone_number !== '' &&
              item.amount !== '' &&
              amount &&
              amount > 0 &&
              amount <= totalAmount
            );
          });

          const remainingAmount =
            formState.reduce((acc, item) => {
              const amount = parseFloat(item.amount);
              if (isNaN(amount)) return acc;
              return acc - amount * 100;
            }, totalAmount) - paidSoFar;

          const handleSubmit = async () => {
            if (isValidForm) {
              try {
                setIsSaving(true);
                const response = await page?.reactQueryResult?.refetch?.();
                const responseData = response?.data as PayflowGetResponse;
                if (
                  responseData &&
                  (responseData.status === PaymentStatus.SENT ||
                    responseData.status === PaymentStatus.OPENED)
                ) {
                  await createSplitPayments();
                } else {
                  setUpdatedPaymentStatus(responseData.status);
                }
                setIsSaving(false);
              } catch (error) {
                setApiError(apiErrorToText(error));
                setIsSaving(false);
              }
            }
          };

          const createSplitPayments = async () => {
            try {
              await SplitPaymentsService.splitPayment(
                page?.params?.payment_id as string,
                {
                  recipients: formState.map(item => ({
                    ...item,
                    amount: parseFloat(item.amount) * 100,
                  })),
                },
              );
              if (remainingAmount <= 0) {
                setLocation(`/${page?.params?.payment_id}`);
              } else {
                createPaymentForRemainderAmount();
              }
            } catch (error) {
              setApiError(apiErrorToText(error));
            }
          };

          const createPaymentForRemainderAmount = async () => {
            if (remainingAmount > 0) {
              try {
                const response =
                  await SplitPaymentsService.createRemainderPayment(
                    page?.params?.payment_id as string,
                  );
                if (response?.payflow_id) {
                  setLocation(`/${response?.payflow_id}`);
                }
              } catch (error) {
                setApiError(apiErrorToText(error));
              }
            }
          };

          return (
            <>
              <PaymentDetails data={data} />
              {updatedPaymentStatus ? (
                <>
                  {/*
              Display a message to the user that the payment status has changed
              Based on status, show a link that redirects to the correct page
            */}
                </>
              ) : null}
              {!updatedPaymentStatus && isSaving ? <Loader /> : null}
              {!updatedPaymentStatus && !isSaving ? (
                <BrandCard heading={t('Add people to the bill')}>
                  <div className="mx-4 mb-4 space-y-4">
                    <p>
                      {t(
                        'Here you can add one or more people who will share the payment with you.',
                      )}
                    </p>
                    <p>
                      {t(
                        'You can decide yourself how the total sum is to be divided between you.',
                      )}
                    </p>
                    <p>
                      {t(
                        'When you have added the other payers, each person will receive an SMS with the associated payment link.',
                      )}
                    </p>
                    <p>
                      {t(
                        'When everyone has paid their share, the original payment is complete.',
                      )}
                    </p>
                  </div>
                  <>
                    {apiError ? (
                      <p className="my-4 text-red-600">{apiError}</p>
                    ) : null}
                  </>
                  <ul className="flex flex-col space-y-4 pb-2">
                    {formState.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="flex flex-col space-y-2 bg-gray-100 px-4 pt-2 pb-4 rounded-md"
                        >
                          {formState.length > 1 ? (
                            <div className="flex flex-row mt-1 justify-between">
                              <div />
                              <button
                                onClick={() => {
                                  setDeleteIndex(index);
                                }}
                              >
                                <TrashIcon className="size-6 text-red-600" />
                              </button>
                            </div>
                          ) : null}
                          <TextField
                            label={t('Name')}
                            value={item.name}
                            onChange={ev => {
                              setFormState(
                                formState.map((item, i) =>
                                  i === index
                                    ? { ...item, name: ev.target.value }
                                    : item,
                                ),
                              );
                            }}
                            placeholder={t('John Doe')}
                            startAdornment={UserIcon}
                          />
                          <TextField
                            label={t('Phone number')}
                            type="tel"
                            value={item.phone_number}
                            onChange={ev => {
                              setFormState(
                                formState.map((item, i) =>
                                  i === index
                                    ? { ...item, phone_number: ev.target.value }
                                    : item,
                                ),
                              );
                            }}
                            placeholder={t('+44 (020) XXXX XXXX')}
                            startAdornment={PhoneIcon}
                          />
                          <TextField
                            label={t('Amount to pay')}
                            type="text"
                            value={item.amount}
                            onChange={ev => {
                              const value = ev.target.value.replace(',', '.');
                              const amount = parseFloat(value);
                              if (value !== '' && isNaN(amount)) return;
                              setFormState(
                                formState.map((item, i) =>
                                  i === index
                                    ? // amount needs to be a string for the TextField component, so intentionally not setting the parsed number value here
                                      { ...item, amount: value }
                                    : item,
                                ),
                              );
                            }}
                            placeholder={currencyFormat(
                              1000,
                              (data?.merchant_country as string) || 'EN',
                            )}
                            startAdornment={BanknotesIcon}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <div className="flex justify-end text-spence">
                    <button
                      onClick={() => {
                        setFormState(oldFormState => [
                          ...oldFormState,
                          { name: '', phone_number: '', amount: '' },
                        ]);
                      }}
                    >
                      + {t('Add another person')}
                    </button>
                  </div>
                  <div className="flex flex-col justify-center items-center my-8 text-lg">
                    <div>{t('Remaing amount for you to pay')}:</div>
                    <div
                      className={`text-2xl ${remainingAmount >= 0 ? 'text-spence' : 'text-red-600'}`}
                    >
                      {currencyFormat(remainingAmount)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <BrandButton
                      onClick={() =>
                        setLocation(`/${page?.params?.payment_id}`)
                      }
                    >
                      {t('Cancel')}
                    </BrandButton>
                    <BrandButton
                      variant="primary"
                      onClick={() => {
                        /* open confirm dialog */
                        setShowConfirmShareBillDialog(true);
                      }}
                      disabled={!isValidForm}
                    >
                      {t('Complete')}
                    </BrandButton>
                  </div>
                </BrandCard>
              ) : null}
              <AlertDialog
                open={deleteIndex !== undefined}
                heading={t('Please confirm')}
                content={t(
                  'Are you sure you want to remove this person from the split bill?',
                  {
                    count: deleteIndex && deleteIndex + 1,
                  },
                )}
                cancelButtonText={t('Cancel')}
                confirmButtonText={t('Remove')}
                variant="error"
                onClose={() => {
                  setDeleteIndex(undefined);
                }}
                onConfirmClick={() => {
                  setDeleteIndex(undefined);
                  setFormState(oldFormState =>
                    oldFormState.filter((_, i) => i !== deleteIndex),
                  );
                }}
              />
              <AlertDialog
                open={showConfirmShareBillDialog}
                heading={t('Split the bill')}
                content={t(
                  'The people you have specified will now receive a link to pay their share of the bill.',
                )}
                cancelButtonText={t('Cancel')}
                confirmButtonText={t('Confirm')}
                variant="success"
                onClose={() => {
                  setShowConfirmShareBillDialog(false);
                }}
                onConfirmClick={handleSubmit}
                Icon={BanknotesIcon}
              />
            </>
          );
        }
        return null;
      }}
    </Page>
  );
};
