/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ResursService {
  /**
   * Initiate Payment
   * @param payflowId
   * @param ssnNumber
   * @returns any Successful Response
   * @throws ApiError
   */
  public static initiatePayment(
    payflowId: string,
    ssnNumber: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/resurs/{payflow_id}/initiate_payment',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'ssn_number': ssnNumber,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Payment Method Details
   * @param payflowId
   * @param paymentMethodName
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getPaymentMethodDetails(
    payflowId: string,
    paymentMethodName: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment-providers/resurs/{payflow_id}/get_payment_method_details',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'payment_method_name': paymentMethodName,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Payment Status
   * @param payflowId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getPaymentStatus(
    payflowId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/resurs/{payflow_id}/get_payment_status',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Callback
   * @param payflowId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static callback(
    payflowId: string,
    requestBody: Record<string, any>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/resurs/{payflow_id}/callback',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
