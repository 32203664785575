import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowContext } from '../contexts/payflow-context';
import { Page, PageData } from '../components/Page';
import { PaymentDetails } from '../sections/PaymentDetails';
import { BrandCard } from '../components/BrandCard';
import { currencyFormat, dateFormat } from '../utils/locale-utils';
import { PayflowGetResponse, PaymentStatus } from '../payflow-client';
import { Pill } from '../components/Pill';
import { PaymentOptionsList } from '../sections/PaymentOptionsList';

interface RecurringPaymentStatusProps {
  data: PayflowGetResponse;
}

const RecurringPaymentStatus: FC<RecurringPaymentStatusProps> = ({data}) => {
  const { t } = useTranslation();
  return (
    <BrandCard
      heading={
        <div className="flex flex-row justify-center items-center">
          <span className="ml-2">{t('Payments')}</span>
        </div>
      }
    >
      <ul className="flex flex-col divide-y space-y-4 text-center">
        {data?.split_payment?.split?.map((split, index) => {
          return (
            <li key={index}>
              <div className={index > 0 ? 'mt-4' : ''}>
                {dateFormat(split?.created_at, data?.merchant_country)}
              </div>
              <div className="flex flex-row justify-center items-center space-x-2">
                <Pill variant={split?.status == PaymentStatus.PAID ? "success": "error"}>
                  {split?.status == PaymentStatus.PAID ? t('Paid') : t('Unpaid')}
                </Pill>
                <span
                  className={
                    split?.status === PaymentStatus.CANCELLED
                      ? 'line-through'
                      : ''
                  }
                >
                  {currencyFormat(split?.total, data?.merchant_country)}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </BrandCard>
  );
}

interface RecurringUpdateMethodProps {
  page: PageData;
}

const RecurringUpdateMethod: FC<RecurringUpdateMethodProps> = ({ page }) => {
  const { t } = useTranslation();
  if (!page?.data || !('payment_options' in page?.data)) {
    return null;
  }
  return (
    <PaymentOptionsList
      title={t('Update payment method')}
      // eslint-disable-next-line
      profile={page?.data?.profile}
      payment_options={page?.data?.payment_options}
      payment_id={page?.params?.payment_id}
    />
  );
}

export const RecurringPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  return (
    <Page
      id="receipt"
      title={t('Receipt')}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page =>
        page?.data && 'payment' in page.data ? (
          <>
            <PaymentDetails data={page?.data} />
            <RecurringUpdateMethod page={page} />
            <RecurringPaymentStatus data={page?.data} />
          </>
        ) : null
      }
    </Page>
  );
};
