export const mapLocale = (locale: string) => {
  const mapped = {
    dk: 'da',
    nb: 'no',
    no: 'no',
    en: 'en',
    da: 'da',
    se: 'sv',
    sv: 'sv',
  };
  return mapped[locale?.toLowerCase() as keyof typeof mapped] || 'en';
};

export function dateFormat(
  date: string | null | undefined,
  locale?: string | null | undefined,
) {
  return (
    date &&
    Intl.DateTimeFormat(mapLocale(locale?.toLowerCase() || 'en'), {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(new Date(date as string))
  );
}

export function dateTimeFormat(
  date: string | null | undefined,
  locale?: string | null | undefined,
) {
  return (
    date &&
    Intl.DateTimeFormat(mapLocale(locale?.toLowerCase() || 'en'), {
      dateStyle: 'short',
      timeStyle: 'medium',
    }).format(new Date(date as string))
  );
}

export function currencyFormat(
  number: number | null | undefined,
  locale?: string | null | undefined,
  currency?: string | null | undefined,
) {
  return Number(number ? number / 100 : 0).toLocaleString(
    mapLocale(locale?.toLowerCase() || 'en'),
    currency ? { style: 'currency', currency } : { minimumFractionDigits: 2 },
  );
}

export function percentFormat(
  number: number | null | undefined,
  locale?: string | null | undefined,
) {
  return Number(number ? number : 0).toLocaleString(
    mapLocale(locale?.toLowerCase() || 'en'),
    {
      style: 'percent',
    },
  );
}
