import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowGetResponsePaymentOption, PayflowGetResponseProfile, PayflowProfileCustomerInputPlacement } from '../../payflow-client';
import { BrandCard } from '../../components/BrandCard';
import { PaymentOptionLogo } from '../../components/PaymentOptionLogo';
import { getNextStepRedirectUrl } from '../../utils/getNextStepRedirectUrl';

type PaymentOptionsListProps = {
  // eslint-disable-next-line
  profile?: PayflowGetResponseProfile | undefined;
  payment_options: PayflowGetResponsePaymentOption[] | undefined;
  payment_id: string | undefined;
  title?: string;
};

export const PaymentOptionsList: FC<PaymentOptionsListProps> = ({
  profile,
  payment_options,
  payment_id,
  title = null
}) => {
  const { t } = useTranslation();
  if (!title) {
    title = t('Select Payment Method');
  }
  if (!payment_options || !payment_id) return null;
  return (
    <BrandCard heading={title}>
      <ul className="flex flex-col space-y-4 pb-4">
        {payment_options?.map(option => (
          <li key={option?.id}>
            <a
              href={getNextStepRedirectUrl(
                `/${payment_id}/${option?.id}`,
                payment_id,
                profile as PayflowGetResponseProfile,
                PayflowProfileCustomerInputPlacement.AFTER_PAYMENT_OPTIONS,
              )}
              className="flex flex-nowrap truncate h-12 p-3 btn-secondary"
            >
              <span className="grow h-full mx-2 truncate">{option?.title}</span>
              <span className="grow-0 h-full">
                <span className="flex h-full space-x-2">
                  {(option?.settings?.logos as string[])?.map(
                    (logo: string) => (
                      <PaymentOptionLogo key={logo} logo={logo} />
                    ),
                  )}
                </span>
              </span>
            </a>
          </li>
        ))}
      </ul>
    </BrandCard>
  );
};
