import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: FC = () => {
  const { t } = useTranslation();

  document.title = '404 | Spense';

  return (
    <div className="relative h-full text-center font-sans">
      <div className="flex flex-col h-full justify-center -mt-[10vh]">
        <h1 className="text-9xl mb-4">404</h1>
        <p className="text-2xl">{t(`This page doesn't exist`)}</p>
      </div>
    </div>
  );
};
