/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorldlineBamboraSetAuthorizedRequest } from '../models/WorldlineBamboraSetAuthorizedRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WorldlineBamboraService {
  /**
   * Webhook
   * @param payflowId
   * @param paymentOptionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static webhook(
    payflowId: string,
    paymentOptionId?: (string | null),
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment-providers/worldline_bambora/{payflow_id}/webhook',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'payment_option_id': paymentOptionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Set Authorized
   * @param payflowId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static setAuthorized(
    payflowId: string,
    requestBody: WorldlineBamboraSetAuthorizedRequest,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/worldline_bambora/{payflow_id}/set-authorized',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
