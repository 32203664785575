import React, { FC, ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboxContent } from '../sections/Keybox/KeyboxContent';
import { PaymentService } from '../payflow-client';
import apiErrorToText from '../utils/api-errors-parser';
import { dateTimeFormat } from '../utils/locale-utils';
import { Loader } from '../components/Loader';

type ShareboxProps = {
  data: any;
  keybox: any;
};

export const Sharebox: FC<ShareboxProps> = ({ data, keybox }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [retrievedAt, setRetrievedAt] = useState<Date | null>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await PaymentService.openKeybox(data?.id, {
        extra: { location_code: code },
      });
      console.log(response, response?.status, response?.ok)
      setRetrievedAt(new Date());
      setLoading(false);
    } catch (error) {
      setError(apiErrorToText(error));
      setLoading(false);
    }
  };

  return (
    <KeyboxContent keybox={keybox}>
      {loading ? (
        <div className="mt-8 mb-4">
          <Loader />
        </div>
      ) : (
        <>
          {retrievedAt || data?.payment?.metadata?.keybox?.retrieved_at ? (
            <div className="flex flex-wrap justify-between text-lg">
              <span>{t('Your key was picked up at')}: </span>
              <span>
                {dateTimeFormat(
                  data?.payment?.metadata?.keybox?.retrieved_at || retrievedAt,
                  data?.merchant_country,
                )}
              </span>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <label htmlFor="location-code" className="block mb-2">
                {t('Enter your location code here')}:
              </label>
              <input
                type="text"
                className="w-full h-14 text-lg border rounded border-gray-600 px-3"
                id="location-code"
                name="code"
                value={code}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setError(null);
                  setCode(event?.target?.value);
                }}
                placeholder="0000"
                autoComplete="off"
              />
              {error && <p className="text-red-600 mt-2">{error}</p>}
              <button
                type="submit"
                className="w-full h-14 font-medium text-lg btn-primary mt-4"
              >
                {t('Open Sharebox')}
              </button>
            </form>
          )}
        </>
      )}
    </KeyboxContent>
  );
};
