/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EFakturaFunnelStatus } from '../models/EFakturaFunnelStatus';
import type { EFakturaInitiateRequest } from '../models/EFakturaInitiateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EFakturaService {
  /**
   * Initiate
   * @param payflowId
   * @param requestBody
   * @returns EFakturaFunnelStatus Successful Response
   * @throws ApiError
   */
  public static initiate(
    payflowId: string,
    requestBody: EFakturaInitiateRequest,
  ): CancelablePromise<EFakturaFunnelStatus> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/efaktura/{payflow_id}/initiate',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
