/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum RefundStatusEnum {
  NOT_REFUNDED = 'NOT_REFUNDED',
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
  FULL = 'FULL',
  FAILED = 'FAILED',
}
