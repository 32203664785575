import { FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  OpenAPI,
  PayflowAttachment,
  PayflowAttachmentRel,
  PayflowGetResponse,
  PaymentService,
  PaymentStatus,
} from '../payflow-client';

import { BrandCard } from '../components/BrandCard';
import { RefundStatusEnum } from '../payflow-client';
import { useTranslation } from 'react-i18next';
import { ArrowDownTray } from '../components/icons/ArrowDownTray';
import { useRoute } from 'wouter';
import Spinner from '../components/Spinner';

interface AttachmentsProps {
  data: PayflowGetResponse;
};

export const Attachments: FC<AttachmentsProps> = ({ data }) => {
  const { t } = useTranslation();

  const isRefunded = data?.refund?.status !== RefundStatusEnum.NOT_REFUNDED;
  const isPaid = !isRefunded && data?.status === PaymentStatus.PAID;

  const [matchReceiptRoute] = useRoute('/:payment_id/receipt');

  const hasReceipt = data.attachments?.find(attachment => attachment.rel === 'RECEIPT');

  let receipt_is_generating = false;

  if (!hasReceipt && matchReceiptRoute && data?.generate_receipts) {
    // We do not have a receipt yet, but it should be there any moment
    receipt_is_generating = true;
  }

  useEffect(() => {
    if (receipt_is_generating) {
      const interval = setInterval(async () => {
        const response = await PaymentService.checkIfReceiptAvailable(data.id!);
        if (response.has_receipt_attachment) {
          window.location.reload();
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [receipt_is_generating]);

  if ((!isPaid || Boolean(data?.attachments?.length) === false) && !receipt_is_generating) {
    return null;
  }

  return (
    <BrandCard heading={t('Documents')}>
      <ul className="flex flex-col space-y-4 pb-4">
        {data?.attachments?.map((attachment, index: number) => {
          if (
            !matchReceiptRoute &&
            attachment?.rel ===
              PayflowAttachmentRel.INVOICEAI_DOCUMENT
          ) {
            return null;
          }
          let name = attachment?.filename;
          if (attachment?.rel === PayflowAttachmentRel.RECEIPT) {
            name = t('Receipt');
          }
          return (
            <li key={attachment?.file_id}>
              <a
                href={`${OpenAPI.BASE}/filestore/${attachment?.file_id}.${attachment?.mime_type?.split('/')[1]}?download=1`}
                className="flex flex-nowrap truncate h-12 p-3 btn-secondary"
                download
              >
                <span className="grow h-full mx-2 truncate">{`${index + 1}. ${name}`}</span>
                <span className="grow-0 w-6">
                  <ArrowDownTray />
                </span>
              </a>
            </li>
          );
        })}
        {receipt_is_generating && (
          <li>
            <a
              href="#"
              className="flex flex-nowrap truncate h-12 p-3 btn-secondary"
            >
              <span className="grow h-full mx-2 truncate">
                {t('Receipt is being generated, please wait...')}
              </span>
              <span className="grow-0 w-6">
                <Spinner />
              </span>
            </a>
          </li>
        )}
      </ul>
    </BrandCard>
  );
};
