import React, { Profiler } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { captureConsoleIntegration } from '@sentry/integrations';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// index.css must be importet before App.tsx to ensure that the styles are applied in correct order
import './index.css';
import App from './App.tsx';
import { onRender } from './utils/profilerOnRender';
import { OpenAPI } from './payflow-client';
import SENTRY_DSN from './sentry_dsn';

// To be able to reuse the App component in Spense Go to preview the Payflow app while setting up branding and
// payment options, we send in PaymentService to the PayflowContext. This way, the App component is not dependent
// in any way on which client is used.

// This allows us to either mock the client, or set up a separate client for the Spense Go preview and pass this in to
// the App component through the PayflowContext.Provider.
import { PaymentService } from './payflow-client';
import { PayflowContext } from './contexts/payflow-context';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    tracesSampleRate: 0.02,
    replaysSessionSampleRate: 0.02,
    replaysOnErrorSampleRate: 1.0,
  });
}

OpenAPI.BASE = '/api/payflow-client/v1';

export const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Profiler id="App" onRender={onRender}>
      <QueryClientProvider client={queryClient}>
        <PayflowContext.Provider value={{ PaymentService }}>
          <App />
        </PayflowContext.Provider>
      </QueryClientProvider>
    </Profiler>
  </React.StrictMode>,
);
