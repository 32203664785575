import React, { FC, useContext } from 'react';

import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { BrandCard } from '../components/BrandCard';
import { BackButton } from '../components/BackButton';

export const TermsAndConditionsPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  return (
    <Page
      id="terms-and-conditions"
      title="Terms and Conditions"
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {() => (
        <>
          <BrandCard heading="Terms and Conditions">
            <BackButton />
            <p className="mt-2 mb-3">
              This is a service used by car dealers, repair and workshops and
              mobility companies (<em>the merchant</em>) to provide the
              following services to vehicle owners (<em>the customer</em>):
            </p>
            <ol className="list-decimal mt-2 mb-3 ml-4">
              <li>Accept signatures for vehicle service invoices</li>
              <li>Accept payment for vehicle repairs</li>
              <li>Accept payment for vehicles</li>
              <li>Accept payment for parts and accessories</li>
              <li>Accept payment for miscellaneous services and products</li>
              <li>Accept payments for road assistance services</li>
              <li>
                Accept recurring payments for insurance, warranty and more
              </li>
              <li>
                Store credit card information to pay recurring fees if
                applicable
              </li>
              <li>
                Provide access to vehicle keys and vehicle retrieval outside
                regular opening hours
              </li>
            </ol>

            <h3 className="text-lg mt-6">Delivery</h3>
            <p className="mt-2 mb-3">
              The customer will receive information via email and/or SMS using
              the contact details provided to the merchant during the vehicle
              delivery process. Additionally, alternative delivery methods may
              be utilized to ensure comprehensive communication.
            </p>
            <p className="mt-2 mb-3">
              The email / SMS contains a link that includes an invoice statement
              to the customer, together with instructions for payment. The T&C
              for the payment partner will be displayed when the customer
              chooses their payment options of choice. If applicable, the
              vehicle keys may be deposited in a key box near the merchants
              location, accessible only by following the link in the email /
              SMS.
            </p>

            <h3 className="text-lg mt-6">Invoice</h3>
            <p className="mt-2 mb-3">
              The merchant will send an invoice statement to the customer. The
              invoice is created in the merchant´s DMS / ERP / invoicing
              software. When transferred to Spense the customer will see the
              detailed invoice, as well as an order summary, but Spense is not
              responsible for the content in the invoice.
            </p>
            <p className="mt-2 mb-3">
              If the customer has questions about the product or service they
              must contact the merchant directly. Questions regarding the
              payment must go directly to the payment provider who operates the
              payment option chosen by the customer. Contact details for both
              parties are displayed in the webpage.
            </p>

            <h3 className="text-lg mt-6">Refunds</h3>
            <p className="mt-2 mb-3">
              Spense is not part of the payment transaction. The customer must
              therefore contact the merchant directly for refunds.
            </p>

            <h3 className="text-lg mt-6">Order confirmation</h3>
            <p className="mt-2 mb-3">
              Once a payment is completed the customer will receive a
              confirmation of the payment and a receipt sent directly to their
              email, if provided to the merchant. They can also download the
              receipt directly from the payment link.
            </p>
            <p className="mt-2 mb-3">
              Questions regarding the receipt must go directly to the merchant
              providing the service.
            </p>

            <h3 className="text-lg mt-6">Payment options</h3>
            <p className="mt-2 mb-3">
              Payments are handled by one of the following payment providers:
            </p>
            <ul className="list-disc my-3 ml-4">
              <li>
                {`Nets Easy `}
                <a
                  href="https://support.nets.eu/article/terms-and-conditions-for-webshop-payment-modules"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                {`Worldline `}
                <a
                  href="https://docs.direct.worldline-solutions.com/en/terms-of-use"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                {`Neonomics `}
                <a
                  href="https://www.neonomics.io/legal/general-terms-and-conditions-all"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                {`Riverty `}
                <a
                  href="https://documents.myafterpay.com/consumer-terms-conditions/dk_dk"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                {`Swedbank Pay `}
                <a
                  href="https://www.swedbankpay.no/siteassets/dokument/avtal-och-vilkar/tjenesteavtaler/generelle-vilkar.pdf"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                {`Stripe `}
                <a
                  href="https://stripe.com/en-no/legal/consumer"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                {`Aiia `}
                <a
                  href="https://www.aiia.eu/legal/aiia-terms-of-use"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </li>
            </ul>

            <h3 className="text-lg mt-6">This service</h3>
            <p className="mt-2 mb-3">
              The software operating this system is provided by Spense AS.
              Spense AS handles the infrastructure of the solution and the
              connections to payment providers through APIs.
            </p>

            <h3 className="text-lg mt-6">Contact details</h3>
            <p className="mt-2 mb-3">Name: Spense AS</p>
            <p className="mt-2 mb-3">Organization number: 924 830 727</p>
            <p className="mt-2 mb-3">Adresse: Grev Wedels plass 9, 0151 Oslo</p>
            <p className="mt-2 mb-3">
              {`Contact: `}
              <a
                href="mailto:support@spense.no"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                support@spense.no
              </a>
            </p>
            <p className="mt-2 mb-3">
              {`Website: `}
              <a
                href="https://spense.no/"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                www.spense.no
              </a>
            </p>
            <BackButton />
          </BrandCard>
        </>
      )}
    </Page>
  );
};
