import React, { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyIcon } from '@heroicons/react/24/outline';

import { KeyboxContent } from '../sections/Keybox/KeyboxContent';
import { PaymentService } from '../payflow-client';
import apiErrorToText from '../utils/api-errors-parser';
import { dateTimeFormat } from '../utils/locale-utils';
import { Loader } from '../components/Loader';
import { AlertDialog } from '../components/AlertDialog';

type LivionKeyProps = {
  data: any;
  keybox: any;
};

export const LivionKey: FC<LivionKeyProps> = ({ data, keybox }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [retrievedAt, setRetrievedAt] = useState<Date | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleOpenKeyboxClick = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmClick = async () => {
    try {
      setLoading(true);
      const response = await PaymentService.openKeybox(data?.id, {});
      console.log(response, response?.status, response?.ok);
      setRetrievedAt(new Date());
      setLoading(false);
    } catch (error) {
      setError(apiErrorToText(error));
      setLoading(false);
    }
  };

  return (
    <>
      <KeyboxContent keybox={keybox}>
        {loading ? (
          <div className="mt-8 mb-4">
            <Loader />
          </div>
        ) : (
          <>
            {retrievedAt || data?.payment?.metadata?.keybox?.retrieved_at ? (
              <div className="flex flex-wrap justify-between text-lg">
                <span>{t('Your key was picked up at')}: </span>
                <span>
                  {dateTimeFormat(
                    data?.payment?.metadata?.keybox?.retrieved_at ||
                      retrievedAt,
                    data?.merchant_country,
                  )}
                </span>
              </div>
            ) : (
              <form onSubmit={handleOpenKeyboxClick}>
                {error && <p className="text-red-600 mt-2">{error}</p>}
                <button
                  type="submit"
                  className="w-full h-14 font-medium text-lg btn-primary mt-4"
                >
                  {t('Open Keybox')}
                </button>
              </form>
            )}
          </>
        )}
      </KeyboxContent>
      <AlertDialog
        heading={t('Open Keybox')}
        content={t('Are you sure you are in front of the Keybox?')}
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirmClick={handleConfirmClick}
        Icon={KeyIcon}
        cancelButtonText={t('Cancel')}
      />
    </>
  );
};
