import { FC, Fragment, ReactNode, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export const AlertDialogActionButton: FC<any> = ({ onClick, children }) => (
  <button
    type="button"
    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
    onClick={onClick}
  >
    {children}
  </button>
);

type AlertDialogProps = {
  open: boolean;
  heading: ReactNode;
  content?: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error' | 'dark';
  Icon?: any;
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  onConfirmClick?: () => void;
  onClose: () => void;
};

const colors = {
  info: {
    bg: 'bg-blue-100',
    text: 'text-blue-600',
    button: {
      confirm: 'bg-blue-600 hover:bg-blue-700',
      cancel: '',
    },
  },
  success: {
    bg: 'bg-green-100',
    text: 'text-green-600',
    button: {
      confirm: 'bg-green-600 hover:bg-green-700',
      cancel: '',
    },
  },
  warning: {
    bg: 'bg-yellow-600',
    text: 'text-white',
    button: {
      confirm: 'bg-yellow-600 hover:bg-yellow-700',
      cancel: '',
    },
  },
  error: {
    bg: 'bg-red-100',
    text: 'text-red-600',
    button: {
      confirm: 'bg-red-600 hover:bg-red-700',
      cancel: '',
    },
  },
  dark: {
    bg: 'bg-gray-600',
    text: 'text-white',
    button: {
      confirm: 'bg-gray-600 hover:bg-gray-700',
      cancel: '',
    },
  },
};

export const AlertDialog: FC<AlertDialogProps> = ({
  open = false,
  heading,
  content,
  variant = 'dark',
  Icon,
  confirmButtonText,
  cancelButtonText,
  onConfirmClick,
  onClose,
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={
                        'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10' +
                        ` ${colors[variant].bg ?? ''}`
                      }
                    >
                      {Icon ? (
                        <Icon
                          className={
                            'h-6 w-6' + ` ${colors[variant].text ?? ''}`
                          }
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationTriangleIcon
                          className={
                            'h-6 w-6' + ` ${colors[variant].text ?? ''}`
                          }
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {heading}
                      </Dialog.Title>
                      {!!content ? (
                        <div className="mt-2">
                          <div className="text-sm text-gray-500">{content}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {onConfirmClick ? (
                    <button
                      type="button"
                      className={
                        'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto' +
                        ` ${colors[variant].button.confirm ?? ''}`
                      }
                      onClick={() => {
                        onConfirmClick?.();
                        onClose();
                      }}
                    >
                      {confirmButtonText ?? t('Confirm')}
                    </button>
                  ) : null}
                  <button
                    type="button"
                    className={
                      'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto' +
                      ` ${colors[variant].button.cancel ?? ''}`
                    }
                    onClick={() => {
                      onClose();
                    }}
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText ?? t('Close')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
