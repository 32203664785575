import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch, Link } from 'wouter';

import { ArrowLeftIcon } from '../icons/ArrowLeft';

export const BackButton: FC = () => {
  const { t } = useTranslation();
  const searchString = useSearch();
  const backTo = searchString?.replace('backTo=', '');
  if (!backTo) return null;
  return (
    <Link
      className="flex flex-nowrap grow-0 truncate h-12 p-3"
      to={decodeURI(backTo)}
    >
      <span className="grow-0 w-6">
        <ArrowLeftIcon />
      </span>
      <span className="grow h-full mx-2 truncate">{t('Go back')}</span>
    </Link>
  );
};
