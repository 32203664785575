import React, { FC } from 'react';
import { useLocation } from 'wouter';

import {
  PayflowGetResponse,
  PayflowSelectPaymentOption,
  WorldlineBamboraService,
} from '../payflow-client';
import { BrandCard } from '../components/BrandCard';

type WorldlineBamboraProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const WorldlineBambora: FC<WorldlineBamboraProps> = ({
  payflow,
  payment_option,
}) => {
  const [, setLocation] = useLocation();
  const containerId = 'iframe-container-div';
  const scriptElement = document.getElementsByClassName(
    'worldline-bambora-script',
  );

  if (scriptElement.length === 0) {
    const script = document.createElement('script');
    script.setAttribute('class', 'worldline-bambora-script');
    script.setAttribute(
      'src',
      'https://static.bambora.com/checkout-sdk-web/latest/checkout-sdk-web.min.js',
    );
    script.setAttribute('type', 'text/javascript');
    script.onload = () => {
      // @ts-expect-error -- 'Bambora' is a global variable
      const checkout = new Bambora.InlineCheckout(
        payment_option?.script_additional_data?.token as string,
      );
      checkout.mount(document.getElementById(containerId) as HTMLElement);

      // @ts-expect-error -- 'Bambora' is a global variable
      checkout.on(Bambora.Event.Authorize, async function (payload: any) {
        console.log('Bambora Authorized', payload);
        await WorldlineBamboraService.setAuthorized(payflow?.id as string, {
          transaction_id: payload.data.txnid,
        });
        setLocation(`/${payflow?.id}/receipt`);
      });

      // @ts-expect-error -- 'Bambora' is a global variable
      checkout.on(Bambora.Event.Cancel, function (payload: any) {
        console.log('Bambora Cancelled', payload);
      });

      // @ts-expect-error -- 'Bambora' is a global variable
      checkout.on(Bambora.Event.CardTypeResolve, function (payload: any) {
        console.log('Bambora CardTypeResolve', payload);
      });

      // @ts-expect-error -- 'Bambora' is a global variable
      checkout.on(Bambora.Event.Close, function (payload: any) {
        console.log('Bambora Closed', payload);
      });

      checkout.on(
        // @ts-expect-error -- 'Bambora' is a global variable
        Bambora.Event.PaymentTypeSelection,
        function (payload: any) {
          console.log('Bambora PaymentTypeSelection', payload);
        },
      );
    };
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  return (
    <BrandCard>
      <div id={containerId} style={{ height: '400px', width: '100%' }}></div>
    </BrandCard>
  );
};
