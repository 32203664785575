/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayflowCreateRemainderPaymentRequest } from '../models/PayflowCreateRemainderPaymentRequest';
import type { PayflowSplitPaymentRequest } from '../models/PayflowSplitPaymentRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SplitPaymentsService {
  /**
   * Split Payment
   * Split the payment to multiple recipients.
   * @param payflowId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static splitPayment(
    payflowId: string,
    requestBody: PayflowSplitPaymentRequest,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/split-payment/{payflow_id}',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Remainder Payment
   * Pay the remainder of a split payment.
   *
   * Creates a payment for letting the original payee pay the remainder of a split payment.
   * @param payflowId
   * @returns PayflowCreateRemainderPaymentRequest Successful Response
   * @throws ApiError
   */
  public static createRemainderPayment(
    payflowId: string,
  ): CancelablePromise<PayflowCreateRemainderPaymentRequest> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/split-payment/{payflow_id}/remainder',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Cancel Split Payment
   * Cancel a split payment.
   * @param splitPayflowId
   * @param payflowId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static cancelSplitPayment(
    splitPayflowId: string,
    payflowId: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/split-payment/{payflow_id}/{split_payflow_id}',
      path: {
        'split_payflow_id': splitPayflowId,
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
