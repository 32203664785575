export const mapSwedbankSupportedLocales = new Map(
  Object.entries({
    en: 'en-US',
    da: 'da-DK',
    dk: 'da-DK',
    fi: 'fi-FI',
    nb: 'nb-NO',
    no: 'nb-NO',
    se: 'sv-SE',
  }),
);
