/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Nothing done.
 * Waiting for the customer to give personal details to retrieve
 * the eFaktura identifier
 */
export enum EFakturaFunnelStatus {
  STARTED = 'STARTED',
  EFAKTURA_IDENTIFIER = 'EFAKTURA_IDENTIFIER',
  INVOICE_ADDED = 'INVOICE_ADDED',
  CONSIGNMENT_PENDING = 'CONSIGNMENT_PENDING',
  CONSIGNMENT_PROCESSED = 'CONSIGNMENT_PROCESSED',
  PROCESSED = 'PROCESSED',
  CONSIGNMENT_FAILED = 'CONSIGNMENT_FAILED',
  INVOICE_FAILED = 'INVOICE_FAILED',
}
