import React, { FC, HTMLInputAutoCompleteAttribute, ReactNode } from 'react';
import cx from 'classnames';

import { Label } from '../Label';
import { HelperText } from '../HelperText';

type TextFieldProps = {
  id?: string;
  className?: any;
  widthClass?: string;
  name?: string;
  type?: 'text' | 'number' | 'email' | 'tel';
  value?: string;
  placeholder?: string;
  startAdornment?: any;
  endAdornment?: any;
  autoComplete?: HTMLInputAutoCompleteAttribute;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const colors = {
  standard: {},
  error: {},
};

export const TextField: FC<TextFieldProps> = ({
  id,
  className,
  widthClass,
  name,
  type = 'text',
  value,
  placeholder,
  startAdornment,
  endAdornment,
  autoComplete,
  required,
  label,
  helperText,
  disabled = false,
  onChange,
}) => {
  const classes = {
    block: true,
    'w-full': !widthClass,
    'rounded-md': true,
    'border-0': true,
    'py-3': true, // this makes the input field too small
    'text-gray-900': true,
    'shadow-sm': true,
    'ring-1': true,
    'ring-inset': true,
    'ring-gray-300': true,
    'placeholder:text-gray-400': true,
    'focus:ring-2': true,
    'focus:ring-inset': true,
    'focus:ring-indigo-600': true,
    'sm:text-sm': true,
    'sm:leading-6': true,
    'pl-10': !!startAdornment,
    'pr-10': !!endAdornment,
  };
  if (widthClass) {
    // @ts-ignore
    classes[widthClass] = true;
  }
  const InputNode = (
    <input
      className={cx(classes, className)}
      id={id}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      autoComplete={autoComplete}
      required={required}
      onChange={onChange}
      disabled={disabled}
    />
  );
  const StartIcon = startAdornment;
  const StartAdornmentNode = startAdornment ? (
    <div
      className={cx({
        'pointer-events-none': true,
        absolute: true,
        'inset-y-0': true,
        'left-0': true,
        flex: true,
        'items-center': true,
        'pl-3': true,
      })}
    >
      <StartIcon className="size-5" />
    </div>
  ) : undefined;
  const EndIcon = endAdornment;
  const EndAdornmentNode = endAdornment ? (
    <div
      className={cx({
        'pointer-events-none': true,
        absolute: true,
        'inset-y-0': true,
        'right-0': true,
        flex: true,
        'items-center': true,
        'pr-3': true,
      })}
    >
      <EndIcon className="size-5" />
    </div>
  ) : undefined;
  const LabelNode = label ? <Label text={label} htmlFor={id} /> : undefined;
  const HelperTextNode = helperText ? (
    <HelperText>{helperText}</HelperText>
  ) : undefined;

  if (LabelNode || HelperTextNode || startAdornment || endAdornment) {
    return (
      <div>
        {LabelNode}
        {LabelNode || startAdornment || endAdornment ? (
          <div
            className={cx({
              relative: true,
              'rounded-md': true,
              'shadow-sm': true,
              'mt-1': !!LabelNode,
            })}
          >
            {StartAdornmentNode}
            {InputNode}
            {EndAdornmentNode}
          </div>
        ) : (
          InputNode
        )}
        {HelperTextNode}
      </div>
    );
  }

  return InputNode;
};

// aria-describedby={helperTextId}
