import React, { FC, PropsWithChildren } from 'react';

import { BrandHeading } from '../BrandHeading';

export const BrandCard: FC<PropsWithChildren<{ heading?: string | React.JSX.Element | null }>> = ({
  children,
  heading,
}) => (
  <div className="brand-card bg-white overflow-hidden py-4 px-6 mt-6 mb-6">
    <BrandHeading>{heading}</BrandHeading>
    {children}
  </div>
);
