import React, {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  PayflowGetResponse,
  PayflowSelectPaymentOption,
  ResursService,
} from '../payflow-client';
import { useTranslation } from 'react-i18next';
import { TextField } from '../components/inputs/TextField';
import { useLocation, useSearch } from 'wouter';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../components/Spinner';
import { BrandCard } from '../components/BrandCard';
import { NorwegianId } from 'norwegian-national-id-validator';

const spanClasses = 'inline-block mb-2 text-base font-medium';
enum ResursStep {
  SSN,
  FinishingFailure,
}

interface StepComponentProps {
  payflow: PayflowGetResponse | null | undefined;
  customerEmail?: string;
  onSubmit: (event: FormEvent<HTMLFormElement>, data: any) => void;
  setShowSpinner?: Dispatch<SetStateAction<boolean>>;
}

const ResursSSNForm: FC<StepComponentProps> = ({
  payflow,
  customerEmail,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(customerEmail);
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState('');

  const handleSsnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSsn(event.target.value);
    setSsnError('');
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = NorwegianId(ssn).isValid();
    if (!isValid) {
      setSsnError(t('Please enter a valid Norwegian SSN (11 digits)'));
      return;
    }
    onSubmit(event, {
      ssn,
      email,
    });
  };

  return (
    <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
      <label htmlFor="email">
        <span className={spanClasses}>{t('Email')}</span>
        <TextField
          type="email"
          id="email"
          name="email"
          value={email || ''}
          onChange={event => setEmail(event.target.value)}
          placeholder={t('Email')}
          autoComplete="email"
          required
        />
      </label>
      <label htmlFor="ssn_number">
        <span className={spanClasses}>{t('SSN number')}</span>
        <TextField
          type="text"
          id="ssn_number"
          name="ssn_number"
          value={ssn}
          onChange={handleSsnChange}
          placeholder={t('SSN number')}
          required
        />
        {ssnError && (
          <span className="text-red-500 text-sm mt-1">{ssnError}</span>
        )}
      </label>
      <button
        type="submit"
        className="w-full h-14 font-medium text-lg btn-primary"
      >
        {t('Next')}
      </button>
    </form>
  );
};

const ResursFailure = ({
  payflow,
}: {
  payflow: PayflowGetResponse | null | undefined;
}) => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: ['payment-status'],
    queryFn: async () =>
      await ResursService.getPaymentStatus(payflow?.id as string),
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <div>{t('Something went wrong while trying to initiate payment.')}</div>
      <div>{t('Please try again or contact support.')}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <div>{t('Payment Status:')}</div>
        <div>{data?.status}</div>
      </div>
      <div>
        <div>{t('Rejected Reason:')}</div>
        <div>{data?.reason}</div>
      </div>
    </div>
  );
};

type PaymentIntegrationProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const Resurs: FC<PaymentIntegrationProps> = ({
  payflow,
  payment_option,
}) => {
  const { t } = useTranslation();
  const searchString = useSearch();
  const [, setLocation] = useLocation();

  const [step, setStep] = useState<ResursStep>(ResursStep.SSN);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const { data: paymentMethod } = useQuery({
    queryKey: ['payment-methods'],
    queryFn: async () =>
      await ResursService.getPaymentMethodDetails(
        payflow?.id as string,
        payment_option?.script_additional_data?.payment_method_name,
      ),
  });

  useEffect(() => {
    if (searchString && searchString.split('=')[0] === 'result') {
      if (searchString.split('=')[1] === 'success') {
        setLocation(`/${payflow?.id}/receipt`);
      }
      if (searchString.split('=')[1] === 'failure') {
        setStep(ResursStep.FinishingFailure);
      }
    }
  }, []);

  const handleInitiatePayment = async (
    event: FormEvent<HTMLFormElement>,
    data: any,
  ) => {
    event.preventDefault();

    try {
      setShowSpinner(true);

      const paymentId = payflow?.id as string;
      const { ssn } = data;

      const paymentData = {
        requested_credit_limit: (payflow?.payment.gross_amount ?? 0) / 100,
      };

      const response = await ResursService.initiatePayment(paymentId, ssn);

      if (response.status === 'TASK_REDIRECTION_REQUIRED') {
        window.open(response.redirect_url, '_self');
        return;
      }

      if (response.status !== 'ACCEPTED') {
        setStep(ResursStep.FinishingFailure);
      }
    } catch (error) {
      console.error('Payment initiation failed:', error);
      setStep(ResursStep.FinishingFailure);
    } finally {
      setShowSpinner(false);
    }
  };

  const convertToTitleCase = (str: string): string => {
    return str
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (showSpinner === true) {
    return (
      <>
        <div className="flex items-center justify-center text-3xl">
          {t('Please wait')}
        </div>
        <div className="flex items-center justify-center py-6">
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <>
      <BrandCard>
        <h1
          style={{
            textAlign: 'center',
            color: '#000',
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: '1.2',
            marginBottom: '1rem',
          }}
        >
          {t('Resurs Bank Documents')}
        </h1>
        {paymentMethod && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginTop: '20px',
            }}
          >
            {paymentMethod.legalLinks.map((link: any, index: number) => (
              <a
                key={index}
                href={
                  ['PRICE_INFO', 'SECCI'].includes(link.type)
                    ? link.url + (payflow?.payment.gross_amount || 0) / 100 // Add the requested credit limit to the URL
                    : link.url
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  display: 'block',
                  color: '#007bff',
                  textDecoration: 'none',
                  marginBottom: '0.5rem',
                  transition: 'color 0.2s ease',
                }}
                onMouseEnter={e => (e.currentTarget.style.color = '#0056b3')}
                onMouseLeave={e => (e.currentTarget.style.color = '#007bff')}
              >
                {convertToTitleCase(link.type)}
              </a>
            ))}
          </div>
        )}
      </BrandCard>
      <BrandCard>
        {ResursStep.SSN === step && (
          <ResursSSNForm
            payflow={payflow}
            customerEmail={
              payment_option?.script_additional_data?.customer.email
            }
            onSubmit={(event, data) => {
              handleInitiatePayment(event, data);
            }}
          />
        )}
        {ResursStep.FinishingFailure === step && (
          <ResursFailure payflow={payflow} />
        )}
      </BrandCard>
    </>
  );
};
