import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { OpenAPI, KeyboxIntegrationDB } from '../../payflow-client';
import { BrandCard } from '../../components/BrandCard';

type KeyboxContentProps = {
  keybox: KeyboxIntegrationDB | undefined;
};

export const KeyboxContent = ({
  keybox,
  children,
}: PropsWithChildren<KeyboxContentProps>) => {
  const { t } = useTranslation();

  return (
    <BrandCard heading={t('Get your key')}>
      {keybox?.picture_url ? (
        <img
          src={`${OpenAPI.BASE}/filestore/${keybox?.picture_url}`}
          alt={t('Location of our keybox')}
        />
      ) : null}
      {keybox?.title ? <h3 className="my-2 text-lg">{keybox?.title}</h3> : null}
      {keybox?.description ? (
        <pre className="my-2 whitespace-pre-wrap font-sans">
          {keybox?.description}
        </pre>
      ) : null}
      {children}
    </BrandCard>
  );
};
