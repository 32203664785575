import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiError } from '../../payflow-client';
import apiErrorToText from '../../utils/api-errors-parser';

type ErrorProps = {
  error?: Error | ApiError | string | null | undefined;
};

export const Error: FC<ErrorProps> = ({ error }) => {
  const { t } = useTranslation();
  let message = t('Something went wrong');

  if (typeof error === 'string') {
    message = error;
    // @ts-expect-error -- optional chaining makes sure this doesn' crash
    error?.status !== 404 && console.error(error);
  } else if (error instanceof ApiError) {
    message = apiErrorToText(error);
    // console.error is already sent from 'apiErrorToText'
  } else if (error) {
    message = error?.message;
    // @ts-expect-error -- optional chaining makes sure this doesn' crash
    error?.status !== 404 && console.error(error);
  }

  return (
    <div className="relative h-full text-center font-sans">
      <div className="flex flex-col h-full justify-center -mt-[10vh]">
        <h1 className="text-8xl mb-4">{t('Oops!')}</h1>
        <p className="text-2xl">{message}</p>
      </div>
    </div>
  );
};
