/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayflowSelectPaymentOptionCCForm } from './PayflowSelectPaymentOptionCCForm';
import type { PayflowSelectPaymentOptionRedirect } from './PayflowSelectPaymentOptionRedirect';
export type PayflowSelectPaymentOption = {
  behaviour: PayflowSelectPaymentOption.behaviour;
  integration_identifier: string;
  redirect?: (PayflowSelectPaymentOptionRedirect | null);
  cc_form?: (PayflowSelectPaymentOptionCCForm | null);
  script_additional_data?: (Record<string, any> | null);
};
export namespace PayflowSelectPaymentOption {
  export enum behaviour {
    REDIRECT = 'redirect',
    CC_FORM = 'cc_form',
    SCRIPT = 'script',
  }
}

