import React, { FC } from 'react';

type LoaderProps = {
  fullPage?: boolean;
};

export const Loader: FC<LoaderProps> = ({ fullPage = false }) => {
  return (
    <div
      className={`flex items-center justify-center ${fullPage ? 'h-screen' : ''}`}
    >
      <div className="loader"></div>
    </div>
  );
};
