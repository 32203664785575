import React, { FC, PropsWithChildren } from 'react';

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="py-6 px-4 sm:p-6 md:py-10 md:px-8 h-screen">
      <div className="max-w-lg mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2 h-full">
        {children}
      </div>
    </div>
  );
};
