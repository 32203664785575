/* eslint-disable */
import {
  PayflowProfileCustomerInputPlacement,
  PayflowProfileCustomerInput,
  PayflowGetResponseProfile,
} from '../payflow-client';

export const getNextStepRedirectUrl = (
  targetUrl: string,
  payflowId: string,
  profile: PayflowGetResponseProfile,
  placement: PayflowProfileCustomerInputPlacement,
) => {
  if (!profile || !profile.customer_input) {
    return targetUrl;
  }

  const steps = profile.customer_input.filter(
    (item: PayflowProfileCustomerInput) =>
      item.enabled === true && item.placement === placement,
  );

  if (!steps || steps.length === 0) {
    return targetUrl;
  }

  const stepId = steps[0].id;

  return `/${payflowId}/step/${stepId}&redirectTo=${encodeURIComponent(targetUrl)}`;
};
