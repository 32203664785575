import React, { FC } from 'react';
import cx from 'classnames';

type LabelProps = {
  text: string;
  htmlFor?: string;
};

export const Label: FC<LabelProps> = ({ text, htmlFor }) => (
  <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor={htmlFor}>
    {text}
  </label>
);
