/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PayflowProfileCustomerInputField = {
  id: string;
  name: string;
  title: string;
  type: PayflowProfileCustomerInputField.type;
  required?: boolean;
  placeholder?: string;
};
export namespace PayflowProfileCustomerInputField {
  export enum type {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    PHONE = 'phone',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
  }
}

