import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';

import { PayflowGetResponse } from '../payflow-client';
import { BrandCard } from '../components/BrandCard';
import { TextField } from '../components/inputs/TextField';
import Spinner from '../components/Spinner';
import { currencyFormat } from '../utils/locale-utils';

const spanClasses = 'inline-block mb-2 text-base font-medium';
const inputClasses =
  'w-full h-14 text-lg border border-gray-600 bg-gray-100 rounded px-3';

enum TestDummyOpenBankingStep {
  ChooseAndLoginToBank,
  LoadingBankDetails,
  SelectBankAcount,
  Finishing
}

function wait(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('resolved');
    }, milliseconds);
  });
}

interface StepComponentProps {
  payflow: PayflowGetResponse | null | undefined;
  onSubmit: (event: FormEvent<HTMLFormElement>, data: any) => void;
}

const TestDummyOpenBanking_ChooseAndLoginToBank: FC<StepComponentProps> = ({ payflow, onSubmit }) => {
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const ssn = formData.get('ssn') as string;
    const email = formData.get('email') as string;
    onSubmit(event, { ssn, email });
  }

  return (
    <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
      <label htmlFor="ssn_number">
        <span className={spanClasses}>{t('SSN number')}</span>
        <TextField
          type="text"
          id="ssn_number"
          name="ssn_number"
          placeholder={t('SSN number')}
          required
        />
      </label>
      <div className={spanClasses}>
        {t('Note: As this is a test payment, you can use any details of any length. The email you enter will not be used for anything, but is there to show how it looks with comparable payment providers.')}
      </div>
      <button
        type="submit"
        className="w-full h-14 font-medium text-lg btn-primary"
      >
        {t('Next')}
      </button>
    </form>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface BankAccount {
  title: string;
  account_number: string;
  balance: number;
}

const TestDummyOpenBanking_SelectBankAccount: FC<StepComponentProps> = ({ payflow, onSubmit }) => {
  const [selectedMethod, setSelectedMethod] = React.useState<number>(0);
  const { t } = useTranslation();

  const spanClasses = 'inline-block mb-2 text-base font-medium';

  const grossAmount = payflow?.payment?.gross_amount as number / 100;

  const bankAccounts: BankAccount[] = [
    {
      title: 'DNB',
      account_number: '1234.56.78901',
      balance: 10034356,
    },
    {
      title: 'DNB',
      account_number: '1234.56.78902',
      balance: 400034330,
    }
  ];

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    onSubmit(event, {
      bankAccount: bankAccounts[selectedMethod]
    });
  }

  return (
    <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
      <label htmlFor="paymentMethod">
        <span className={spanClasses}>{t('Select bank account')}</span>
        <div className="space-y-4">
          {bankAccounts?.map((bankAccount: BankAccount, index: number) => (
            <label
              key={index}
              className={classNames(
                index == selectedMethod
                  ? 'border-indigo-600 ring-2 ring-indigo-600 bg-indigo-50'
                  : 'border-gray-300',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
              )}
            >
              <input
                type="radio"
                name="payment-method"
                value={index}
                className="sr-only"
                onChange={() => {
                  setSelectedMethod(index);
                }}
              />
              <span className="flex items-center">
                <span className="flex flex-col text-sm">
                  <span
                    id="server-size-0-label"
                    className="font-medium text-gray-900"
                  >
                    {bankAccount.title}
                  </span>
                  <span
                    id="server-size-0-description-0"
                    className="text-gray-500"
                  >
                    <span className="block sm:inline">{t("Account number:")} {bankAccount.account_number}</span><br />
                    <span className="block sm:inline">{t("Balance:")} {currencyFormat(bankAccount.balance)}</span>
                  </span>
                </span>
              </span>
              <span
                className="pointer-events-none absolute -inset-px rounded-lg border-2"
                aria-hidden="true"
              ></span>
            </label>
          ))}
        </div>
      </label>
      <button
        type="submit"
        className="w-full h-14 font-medium text-lg btn-primary"
      >
        {t('Next')}
      </button>
    </form>
  );
}

type PaymentIntegrationProps = {
  payflow: PayflowGetResponse | null | undefined;
};

export const TestDummyOpenBanking: FC<PaymentIntegrationProps> = ({ payflow }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const [step, setStep] = useState<TestDummyOpenBankingStep>(TestDummyOpenBankingStep.ChooseAndLoginToBank);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (step == TestDummyOpenBankingStep.LoadingBankDetails) {
      setShowSpinner(true);
      wait(3000).then(() => {
        setShowSpinner(false);
        setStep(TestDummyOpenBankingStep.SelectBankAcount);
      });
    }
    if (step == TestDummyOpenBankingStep.Finishing) {
      setShowSpinner(true);
      wait(3000).then(() => {
        setShowSpinner(false);
        setLocation('/' + payflow?.id + '/receipt');
      });
    }
  }, [step]);

  return (
    <BrandCard>
      {showSpinner === true && (
        <>
          <div className="flex items-center justify-center text-3xl">
            {t("Loading...")}
          </div>
          <div className="flex items-center justify-center py-6">
            <Spinner />
          </div>
        </>
      )}
      {TestDummyOpenBankingStep.ChooseAndLoginToBank === step && (
        <TestDummyOpenBanking_ChooseAndLoginToBank
          payflow={payflow}
          onSubmit={(event, data) => {
            setStep(TestDummyOpenBankingStep.LoadingBankDetails);
          }}
        />
      )}
      {TestDummyOpenBankingStep.SelectBankAcount === step && (
        <TestDummyOpenBanking_SelectBankAccount
          payflow={payflow}
          onSubmit={(event, data) => {
            setStep(TestDummyOpenBankingStep.Finishing);
          }}
        />
      )}
    </BrandCard>
  );
};