import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useRoute } from 'wouter';

import { OrganizationSupportInformation } from '../../payflow-client';
import { BrandCard } from '../../components/BrandCard';
import { AnchorButton } from '../../components/AnchorButton';
import { PhoneIcon } from '../../components/icons/Phone';
import { EnvelopeIcon } from '../../components/icons/Envelope';
import { InformationCircleIcon } from '../../components/icons/InformationCircle';

type HelpSectionProps = {
  support_information: OrganizationSupportInformation | undefined;
};

export const HelpSection: FC<HelpSectionProps> = ({ support_information }) => {
  const { t } = useTranslation();
  const { payment_id } = useParams();
  const [location] = useLocation();
  const [matchReadMore] = useRoute('/*/read-more');
  const [matchTermsAndConditions] = useRoute('/*/terms-and-conditions');

  if (!support_information) return null;

  return (
    <BrandCard heading={t('Do you need help?')}>
      <ul className="flex flex-col space-y-4 pb-4">
        {!matchReadMore && !matchTermsAndConditions ? (
          <li>
            <AnchorButton
              href={`/${payment_id}/read-more?backTo=${location}`}
              variant="secondary"
              icon={<InformationCircleIcon />}
            >
              {t('About the payment methods')}
            </AnchorButton>
          </li>
        ) : null}
        {support_information?.phone_number ? (
          <li>
            <AnchorButton
              href={`tel:${support_information?.phone_number}`}
              variant="secondary"
              icon={<PhoneIcon />}
            >
              {support_information?.phone_number || t('Phone')}
            </AnchorButton>
          </li>
        ) : null}
        {support_information?.email ? (
          <li>
            <AnchorButton
              href={`mailto:${support_information?.email}`}
              variant="secondary"
              icon={<EnvelopeIcon />}
            >
              {support_information?.email || t('Email')}
            </AnchorButton>
          </li>
        ) : null}
      </ul>
    </BrandCard>
  );
};
