import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/outline';

import { PayflowContext } from '../contexts/payflow-context';
import {
  SplitPaymentsService,
  PayflowGetResponse,
  PaymentStatus,
  PayflowGetResponsePaymentOption,
} from '../payflow-client';
import { Page } from '../components/Page';
import { AlertDialog } from '../components/AlertDialog';
import { TextField } from '../components/inputs/TextField';
import { BrandCard } from '../components/BrandCard';
import { BrandButton } from '../components/inputs/BrandButton';
import { PaymentDetails } from '../sections/PaymentDetails';
import { currencyFormat } from '../utils/locale-utils';
import apiErrorToText from '../utils/api-errors-parser';
import { Loader } from '../components/Loader';

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

const people = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
  { id: 3, name: 'Devon Webb' },
  { id: 4, name: 'Tom Cook' },
  { id: 5, name: 'Tanya Fox' },
  { id: 6, name: 'Hellen Schmidt' },
  { id: 7, name: 'Caroline Schultz' },
  { id: 8, name: 'Mason Heaney' },
  { id: 9, name: 'Claudie Smitham' },
  { id: 10, name: 'Emil Schaefer' },
]

interface PaymentMethodSelectProps {
  paymentMethods: PayflowGetResponsePaymentOption[];
  onChange: (ev: any) => void;
}

const PaymentMethodSelect: FC<PaymentMethodSelectProps> = (props) => {
  const { paymentMethods, onChange } = props;
  const [selected, setSelected] =
    useState<PayflowGetResponsePaymentOption | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (selected) {
      onChange({ target: { value: selected.id } });
    }
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <Label className="block text-sm font-medium leading-6 text-gray-900">
        {t('How to pay')}
      </Label>
      <div className="relative mt-2">
        {selected ? (
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span className="block truncate">{selected.title}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </ListboxButton>
        ) : (
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span className="block truncate">
              <em>{t('Select')}</em>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </ListboxButton>
        )}

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {paymentMethods.map((paymentMethod, index) => (
            <ListboxOption
              key={paymentMethod.id}
              value={paymentMethod}
              className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
            >
              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                {paymentMethod.title}
              </span>

              <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

// TODO: Handle redirect to the remainder payment if the original payment link is opened after the
// split payment process has been completed, and the remainder payment is unpaid.
// This should probably be handled in useRedirect.ts?

export const SplitPaymentBetweenMethodsPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const [updatedPaymentStatus, setUpdatedPaymentStatus] = useState<
    PaymentStatus | undefined
  >();
  const [apiError, setApiError] = useState<string | undefined>();
  const [formState, setFormState] = useState([
    { amount: '', payment_option_id: '' },
  ]);
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>(undefined);
  const [showConfirmShareBillDialog, setShowConfirmShareBillDialog] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Page
      id="split-payment"
      title={t('Split the bill')}
      queryFn={PaymentService ? PaymentService.get : null}
      pageLoader={!isSaving}
    >
      {page => {
        if (page?.data && 'payment_options' in page?.data) {
          const data = page?.data as PayflowGetResponse;
          const paidSoFar =
            data?.split_payment?.split
              ?.filter((split: any) => split.status == PaymentStatus.PAID)
              .reduce((acc: number, split: any) => acc + split.total, 0) || 0;
          const totalAmount = data?.payment?.gross_amount || 0;
          const isValidForm = formState.every(item => {
            const amount = !isNaN(parseFloat(item.amount))
              ? parseFloat(item.amount) * 100
              : undefined;

            return (
              item.amount !== '' &&
              amount &&
              amount > 0 &&
              amount <= totalAmount &&
              item.payment_option_id !== ''
            );
          });

          const remainingAmount =
            formState.reduce((acc, item) => {
              const amount = parseFloat(item.amount);
              if (isNaN(amount)) return acc;
              return acc - amount * 100;
            }, totalAmount) - paidSoFar;

          const handleSubmit = async () => {
            if (isValidForm) {
              try {
                setIsSaving(true);
                const response = await page?.reactQueryResult?.refetch?.();
                const responseData = response?.data as PayflowGetResponse;
                if (
                  responseData &&
                  (responseData.status === PaymentStatus.SENT ||
                    responseData.status === PaymentStatus.OPENED)
                ) {
                  await createSplitPayments();
                } else {
                  setUpdatedPaymentStatus(responseData.status);
                }
                setIsSaving(false);
              } catch (error) {
                setApiError(apiErrorToText(error));
                setIsSaving(false);
              }
            }
          };

          const createSplitPayments = async () => {
            try {
              await SplitPaymentsService.splitPayment(
                page?.params?.payment_id as string,
                {
                  payment_options: formState.map(item => ({
                    ...item,
                    amount: parseFloat(item.amount) * 100,
                  })),
                },
              );
              if (remainingAmount <= 0) {
                setLocation(`/${page?.params?.payment_id}`);
              } else {
                createPaymentForRemainderAmount();
              }
            } catch (error) {
              setApiError(apiErrorToText(error));
            }
          };

          const createPaymentForRemainderAmount = async () => {
            if (remainingAmount > 0) {
              try {
                const response =
                  await SplitPaymentsService.createRemainderPayment(
                    page?.params?.payment_id as string,
                  );
                if (response?.payflow_id) {
                  setLocation(`/${response?.payflow_id}`);
                }
              } catch (error) {
                setApiError(apiErrorToText(error));
              }
            }
          };

          return (
            <>
              <PaymentDetails data={data} />
              {updatedPaymentStatus ? (
                <>
                  {/*
              Display a message to the user that the payment status has changed
              Based on status, show a link that redirects to the correct page
            */}
                </>
              ) : null}
              {!updatedPaymentStatus && isSaving ? <Loader /> : null}
              {!updatedPaymentStatus && !isSaving ? (
                <BrandCard heading={t('Split between payment methods')}>
                  <div className="mx-4 mb-4 space-y-4">
                    <p>
                      {t(
                        'Split up the payment between several different payment methods.',
                      )}
                    </p>
                  </div>
                  <>
                    {apiError ? (
                      <p className="my-4 text-red-600">{apiError}</p>
                    ) : null}
                  </>
                  <ul className="flex flex-col space-y-4 pb-2">
                    {formState.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="flex flex-col space-y-2 bg-gray-100 px-4 pt-2 pb-4 rounded-md"
                        >
                          {formState.length > 1 ? (
                            <div className="flex flex-row mt-1 justify-between">
                              <div />
                              <button
                                onClick={() => {
                                  setDeleteIndex(index);
                                }}
                              >
                                <TrashIcon className="size-6 text-red-600" />
                              </button>
                            </div>
                          ) : null}

                          <TextField
                            label={t('Amount to pay')}
                            type="text"
                            value={item.amount}
                            onChange={ev => {
                              const value = ev.target.value.replace(',', '.');
                              const amount = parseFloat(value);
                              if (value !== '' && isNaN(amount)) return;
                              setFormState(
                                formState.map((item, i) =>
                                  i === index
                                    ? // amount needs to be a string for the TextField component, so intentionally not setting the parsed number value here
                                      { ...item, amount: value }
                                    : item,
                                ),
                              );
                            }}
                            placeholder={currencyFormat(
                              1000,
                              (data?.merchant_country as string) || 'EN',
                            )}
                            startAdornment={BanknotesIcon}
                          />
                          <PaymentMethodSelect
                            paymentMethods={data?.payment_options || []}
                            onChange={ev => {
                              setFormState(
                                formState.map((item, i) =>
                                  i === index
                                    ? { ...item, payment_option_id: ev.target.value }
                                    : item,
                                ),
                              );
                            }}

                          />
                        </li>
                      );
                    })}
                  </ul>
                  <div className="flex justify-end text-spence">
                    <button
                      onClick={() => {
                        setFormState(oldFormState => [
                          ...oldFormState,
                          { amount: '', payment_option_id: '' },
                        ]);
                      }}
                    >
                      + {t('Add another method of paying')}
                    </button>
                  </div>
                  <div className="flex flex-col justify-center items-center my-8 text-lg">
                    <div>{t('Remaining amount for you to pay')}:</div>
                    <div
                      className={`text-2xl ${remainingAmount >= 0 ? 'text-spence' : 'text-red-600'}`}
                    >
                      {currencyFormat(remainingAmount)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <BrandButton
                      onClick={() =>
                        setLocation(`/${page?.params?.payment_id}`)
                      }
                    >
                      {t('Cancel')}
                    </BrandButton>
                    <BrandButton
                      variant="primary"
                      onClick={() => {
                        /* open confirm dialog */
                        setShowConfirmShareBillDialog(true);
                      }}
                      disabled={!isValidForm}
                    >
                      {t('Complete')}
                    </BrandButton>
                  </div>
                </BrandCard>
              ) : null}
              <AlertDialog
                open={deleteIndex !== undefined}
                heading={t('Please confirm')}
                content={t(
                  'Are you sure you want to remove this part payment?',
                  {
                    count: deleteIndex && deleteIndex + 1,
                  },
                )}
                cancelButtonText={t('Cancel')}
                confirmButtonText={t('Remove')}
                variant="error"
                onClose={() => {
                  setDeleteIndex(undefined);
                }}
                onConfirmClick={() => {
                  setDeleteIndex(undefined);
                  setFormState(oldFormState =>
                    oldFormState.filter((_, i) => i !== deleteIndex),
                  );
                }}
              />
              <AlertDialog
                open={showConfirmShareBillDialog}
                heading={t('Split the bill')}
                content={t(
                  'You will now proceed to split the bill between the selected payment methods. Are you sure you want to continue?',
                )}
                cancelButtonText={t('Cancel')}
                confirmButtonText={t('Confirm')}
                variant="success"
                onClose={() => {
                  setShowConfirmShareBillDialog(false);
                }}
                onConfirmClick={handleSubmit}
                Icon={BanknotesIcon}
              />
            </>
          );
        }
        return null;
      }}
    </Page>
  );
};
