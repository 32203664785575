import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { PaymentDetails } from '../sections/PaymentDetails';
import { RivertyPending } from '../payment-integrations/Riverty';
import { PayflowGetResponse, PayflowGetResponsePaymentOption, PayflowSelectPaymentOption } from '../payflow-client';

export const PendingPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  return (
    <Page
      id="pending"
      title={t("Pending")}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page => {
        if (page?.data && 'payment' in page.data) {
          const payment_option = page?.data?.selected_payment_option;
          const get_response = page?.data as PayflowGetResponse;

          function renderPaymentOption() {
            switch (payment_option?.integration_identifier) {
              case undefined:
                console.log('No integration_identifier found in payment_option');
                break;
              case 'riverty':
                return <RivertyPending payflow={get_response} />;
            }
            return null;
          }

          return (
            <>
              {renderPaymentOption()}
              <PaymentDetails data={page?.data} />
            </>
          );
        }
      }}
    </Page>
  );
};
