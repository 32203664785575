import { FC } from 'react';
import { useLocation } from 'wouter';

import { PayflowSelectPaymentOption } from '../payflow-client';

type NeonomicsProps = {
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const Neonomics: FC<NeonomicsProps> = ({ payment_option }) => {
  const [, setLocation] = useLocation();

  window.location = payment_option?.script_additional_data?.url as string & Location;

  return null;
};
