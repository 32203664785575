/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RivertyAvailableMethodsRequest } from '../models/RivertyAvailableMethodsRequest';
import type { RivertyGetOutcomeRequest } from '../models/RivertyGetOutcomeRequest';
import type { RivertyInitiateRequest } from '../models/RivertyInitiateRequest';
import type { RivertyInitiateResponse } from '../models/RivertyInitiateResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RivertyService {
  /**
   * Get Available Methods
   * @param payflowId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAvailableMethods(
    payflowId: string,
    requestBody: RivertyAvailableMethodsRequest,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/riverty/{payflow_id}/get_available_methods',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Initiate
   * @param payflowId
   * @param requestBody
   * @returns RivertyInitiateResponse Successful Response
   * @throws ApiError
   */
  public static initiate(
    payflowId: string,
    requestBody: RivertyInitiateRequest,
  ): CancelablePromise<RivertyInitiateResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/riverty/{payflow_id}/initiate',
      path: {
        'payflow_id': payflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Outcome
   * @param payflowId
   * @returns RivertyGetOutcomeRequest Successful Response
   * @throws ApiError
   */
  public static getOutcome(
    payflowId: string,
  ): CancelablePromise<RivertyGetOutcomeRequest> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment-providers/riverty/{payflow_id}/get_outcome',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Reset Expired Payment
   * @param payflowId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static resetExpiredPayment(
    payflowId: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment-providers/riverty/{payflow_id}/reset_expired_payment',
      path: {
        'payflow_id': payflowId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Webhook
   * @param payflowId
   * @param paymentOptionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static webhook(
    payflowId: string,
    paymentOptionId?: (string | null),
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment-providers/riverty/{payflow_id}/webhook',
      path: {
        'payflow_id': payflowId,
      },
      query: {
        'payment_option_id': paymentOptionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
