import { createContext } from 'react';

import { PaymentService } from '../payflow-client';

export type PayflowContextType = {
  PaymentService?: typeof PaymentService | undefined;
};

export const PayflowContext = createContext<PayflowContextType>({
  PaymentService: undefined,
});
