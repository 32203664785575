import { useTranslation } from "react-i18next";
import { PaymentStatus } from "../payflow-client";

export const useMapStatusToText = () => {
  const { t } = useTranslation();
  return (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.CREATED:
        return t('Created');
      case PaymentStatus.PAID:
        return t('Paid');
      case PaymentStatus.SENT:
        return t('Sent');
      case PaymentStatus.OPENED:
        return t('Opened');
      case PaymentStatus.PENDING:
        return t('Pending');
      case PaymentStatus.FAILED:
        return t('Failed');
      case PaymentStatus.CANCELLED:
        return t('Cancelled');
      default:
        return null;
    }
  }
};

export const useMapStatusToVariant = () => {
  return (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return 'success' as const;
      case PaymentStatus.CREATED:
      case PaymentStatus.SENT:
      case PaymentStatus.OPENED:
      case PaymentStatus.PENDING:
        return 'info' as const;
      case PaymentStatus.FAILED:
        return 'error' as const;
      case PaymentStatus.CANCELLED:
      default:
        return 'dark' as const;
    }
  }
};
