import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { BrandCard } from '../components/BrandCard';
import { BackButton } from '../components/BackButton';
import MarkdownWrapper from '../components/MarkdownWrapper';
import { PayflowGetResponsePaymentOption } from '../payflow-client';

export const ReadMorePage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  return (
    <Page
      id="read-more"
      title={t('Read More')}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page => {
        if (page?.data && 'payment_options' in page.data) {
          const integrationIdentifiers: string[] = [];
          const payment_options = page?.data?.payment_options?.filter(
            payment_option => {
              if (
                !integrationIdentifiers.includes(
                  payment_option.integration_identifier,
                )
              ) {
                integrationIdentifiers.push(
                  payment_option.integration_identifier,
                );
                return true;
              }
              return false;
            },
          );
          return (
            <>
              <BackButton />
              {payment_options?.map(payment_option =>
                payment_option?.settings?.read_more_explanation ? (
                  <BrandCard key={payment_option?.id}>
                    <MarkdownWrapper>
                      {
                        payment_option?.settings
                          ?.read_more_explanation as string
                      }
                    </MarkdownWrapper>
                  </BrandCard>
                ) : null,
              )}
              <BackButton />
            </>
          );
        }
        return null;
      }}
    </Page>
  );
};
