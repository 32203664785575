/* eslint @typescript-eslint/no-unused-vars: "off", no-console: off */

/**
 * This function is used to profile the rendering of React components.
 * See https://react.dev/reference/react/Profiler for details.
 */

export const onRender = (
  id: string,
  phase: 'mount' | 'update' | 'nested-update',
  actualDuration: number, // ms spent rendering the component
  baseDuration: number, // ms estimated time to render the entire subtree without optimizations
  startTime: number, // numeric timestamp for when React began rendering this update
  commitTime: number, // numeric timestamp for when React committed this update
) => {
  // Profiler is disabled by default in production build, so no need to do any checks for env
  // Use this variable to enable/disable logging while developing
  const shouldLog = false;
  if (shouldLog) {
    console.log({
      phase,
      actualDuration,
      baseDuration,
    });
  }
};
