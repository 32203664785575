/* eslint-disable */
import React, {
  FC,
  useContext,
  useState,
  ChangeEvent,
  MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

import { PayflowProfileCustomerInput, ApiError } from '../payflow-client';
import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { BrandCard } from '../components/BrandCard';
import { BrandHeading } from '../components/BrandHeading';
import { AnchorButton } from '../components/AnchorButton';
import { Loader } from '../components/Loader';
import apiErrorToText from '../utils/api-errors-parser';
import { PaymentDetails } from '../sections/PaymentDetails';
import { queryClient } from '../main';

function wait(milliseconds: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, milliseconds);
  });
}

export const AdditionalPaymentStepPage: FC = () => {
  const { t } = useTranslation();
  const [location, setLocation] = useLocation();
  const { PaymentService } = useContext(PayflowContext);
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleNextStepClick = async (
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    currentStepData: PayflowProfileCustomerInput,
    redirectTo: string | null,
    payflowId: string,
  ) => {
    event.preventDefault();

    const requiredFields = currentStepData?.fields?.filter(
      field => field?.required,
    );

    if (
      requiredFields &&
      Object.keys(formState).length < requiredFields?.length
    ) {
      const updatedErrors = {};
      requiredFields.forEach(field => {
        // @ts-ignore
        updatedErrors[field?.name] = t('Required');
      });
      setErrors(previous => ({
        ...previous,
        ...updatedErrors,
      }));
      setIsLoading(false);
    } else {
      if (Object.keys(errors).length > 0) {
        return;
      }
      setIsLoading(true);
      try {
        await PaymentService?.saveCustomerInput(
          payflowId,
          currentStepData?.id,
          formState,
        );

        // invalidate query cache
        await queryClient.invalidateQueries({
          predicate: query => {
            return true;
          },
        });

        if (redirectTo) {
          setLocation(redirectTo);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setErrors(previous => ({
            ...previous,
            apiError: apiErrorToText(error),
          }));
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Page
      id="pre-payment"
      title={t('Payment')}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page => {
        if (page?.data && 'profile' in page.data) {
          const searchParams = new URLSearchParams(window.location.search);
          const redirectTo = searchParams.get('redirectTo');
          const step_id = page.params?.step_id;
          const currentStepData = page?.data?.profile?.customer_input.find(
            step => step.id === step_id,
          );

          if (currentStepData?.completed && redirectTo) {
            setLocation(redirectTo);
            return null;
          }

          const handleInputChange = (
            event: ChangeEvent<HTMLInputElement>,
            currentStepData: PayflowProfileCustomerInput,
          ) => {
            if (event.target.type === 'checkbox') {
              setFormState({
                [event.target.name]: event.target.checked,
              });
              setErrors(previous => {
                if (event.target.checked) {
                  const tmp = { ...previous };
                  delete tmp[event.target.name];
                  return tmp;
                } else {
                  return {
                    ...previous,
                    [event.target.name]: t('Required'),
                  };
                }
              });
            } else {
              setFormState({
                [event.target.name]: event.target.value,
              });
              setErrors(previous => {
                if (event.target.value) {
                  const tmp = { ...previous };
                  delete tmp[event.target.name];
                  return tmp;
                } else {
                  return {
                    ...previous,
                    [event.target.name]: t('Required'),
                  };
                }
              });
            }
          };

          return (
            <>
              <PaymentDetails data={page?.data} />
              <BrandCard>
                {currentStepData?.fields?.map((field: any) => {
                  return (
                    <div key={field?.id} data-id={field?.id} className="mb-4">
                      <BrandHeading>
                        {currentStepData?.description}
                      </BrandHeading>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          {field?.type === 'checkbox' ? (
                            <div className="mb-4">
                              <div className="flex text-lg leading-tight">
                                <div className="flex items-center">
                                  {/* Zero-width space character, used to align checkbox properly */}
                                  &#8203;
                                  <input
                                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                    type="checkbox"
                                    id={field?.name}
                                    name={field?.name}
                                    required={field?.required}
                                    onChange={event =>
                                      handleInputChange(event, currentStepData)
                                    }
                                  />
                                  {field?.required ? (
                                    <span className="pl-2 text-red-600">*</span>
                                  ) : (
                                    <span className="pl-4"></span>
                                  )}
                                </div>
                                {field?.title ? (
                                  <label className="ms-2" htmlFor={field?.name}>
                                    <pre className="whitespace-pre-line text-lg text-pretty font-sans">
                                      {field?.title}
                                    </pre>
                                  </label>
                                ) : null}
                              </div>
                              {errors[field?.name] ? (
                                <div className="pt-2 pl-10 text-red-600">
                                  {errors[field?.name]}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  );
                })}
                {errors.apiError ? (
                  <div className="my-4 text-red-600">{errors.apiError}</div>
                ) : null}
                {isLoading ? null : (
                  <AnchorButton
                    href="#"
                    variant="primary"
                    centerText
                    onClick={event =>
                      handleNextStepClick(
                        event,
                        currentStepData as PayflowProfileCustomerInput,
                        redirectTo,
                        page?.params?.payment_id as string,
                      )
                    }
                  >
                    {currentStepData?.submit_label || t('Continue')}
                  </AnchorButton>
                )}
              </BrandCard>
            </>
          );
        }

        setLocation(`/${page?.params?.payment_id}`);
        return null;
      }}
    </Page>
  );
};
