import React, { FC, PropsWithChildren, ReactNode, MouseEventHandler } from 'react';

type AnchorButtonProps = {
  href: string;
  variant?: 'primary' | 'secondary';
  icon?: ReactNode;
  centerText?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const AnchorButton: FC<PropsWithChildren<AnchorButtonProps>> = ({
  children,
  href,
  variant = 'secondary',
  icon,
  centerText,
  onClick,
}) => (
  <a
    href={href}
    className={`flex flex-nowrap truncate h-12 p-3 btn-${variant}`}
    onClick={onClick}
  >
    {icon ? <span className="grow-0 w-6">{icon}</span> : null}
    {children ? (
      <span className={`grow h-full mx-2 truncate ${centerText ? 'text-center' : ''}`}>{children}</span>
    ) : null}
  </a>
);
