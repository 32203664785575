import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

type HelperTextProps = {
  children: ReactNode;
};

export const HelperText: FC<HelperTextProps> = ({ children }) => {
  return <div className="mt-1 ml-2 text-sm text-gray-500">{children}</div>;
};
