/* eslint @typescript-eslint/require-await: off, @typescript-eslint/no-misused-promises: off, @typescript-eslint/no-floating-promises: off */

import { PaymentService } from '../payflow-client';

const SECONDS_BETWEEN_PINGS = 20;

let timerReference: number | NodeJS.Timeout = 0;
let hasInitialized = false;

async function initializePing(payflow_id: string) {
  if (hasInitialized) {
    return;
  }

  if (timerReference) {
    clearInterval(timerReference);
    timerReference = 0;
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      clearInterval(timerReference);
      timerReference = 0;
    } else if (!document.hidden && !timerReference) {
      timerReference = setInterval(async () => {
        await PaymentService.ping(payflow_id);
      }, 1000 * SECONDS_BETWEEN_PINGS);
      PaymentService.ping(payflow_id);
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange);

  handleVisibilityChange();

  hasInitialized = true;
}

export default initializePing;
