export const mapDibsSupportedLocales = new Map(
  Object.entries({
    en: 'en-GB',
    da: 'da-DK',
    dk: 'da-DK',
    nl: 'nl-NL',
    ee: 'et-EE',
    fi: 'fi-FI',
    fr: 'fr-FR',
    de: 'de-DE',
    it: 'it-IT',
    lv: 'lv-LV',
    lt: 'lt-LT',
    nb: 'nb-NO',
    no: 'nb-NO',
    pl: 'pl-PL',
    es: 'es-ES',
    sk: 'sk-SK',
    se: 'sv-SE',
  }),
);
