/* eslint  @typescript-eslint/no-unsafe-member-access: off, @typescript-eslint/no-unsafe-call: off */

import { PayflowProfileBranding } from '../../payflow-client/models/PayflowProfileBranding';

export const mapLegacyTheme = (theme: PayflowProfileBranding) => ({
  '--background_color': theme?.background_color,
  '--text_color': theme?.text_color,
  '--primary_color': theme?.primary_color,
  '--font_family_heading': `'${theme?.fonts?.heading}', sans-serif`,
  '--font_family_body': `'${theme?.fonts?.body}', sans-serif`,
  '--primary_button_text_color': theme?.primary_button?.text_color,
  '--primary_button_background_color': theme?.primary_button?.background_color,
  '--primary_button_border_color': theme?.primary_button?.border_color,
  '--primary_button_border_width': `${theme?.primary_button?.border_width || 0}px`,
  '--primary_button_border_radius': `${theme?.primary_button?.border_radius || 0}px`,
  '--primary_button_hover_text_color': theme?.primary_button?.hover_text_color,
  '--primary_button_hover_background_color':
    theme?.primary_button?.hover_background_color,
  '--primary_button_hover_border_color':
    theme?.primary_button?.hover_border_color,
  '--secondary_button_text_color': theme?.secondary_button?.text_color,
  '--secondary_button_background_color':
    theme?.secondary_button?.background_color,
  '--secondary_button_border_color': theme?.secondary_button?.border_color,
  '--secondary_button_border_width': `${theme?.secondary_button?.border_width || 0}px`,
  '--secondary_button_border_radius': `${theme?.secondary_button?.border_radius || 0}px`,
  '--secondary_button_hover_text_color':
    theme?.secondary_button?.hover_text_color,
  '--secondary_button_hover_background_color':
    theme?.secondary_button?.hover_background_color,
  '--secondary_button_hover_border_color':
    theme?.secondary_button?.hover_border_color,
});

export const applyLegacyTheme = (
  branding?: PayflowProfileBranding | undefined,
) => {
  if (!branding) return;
  const mappedTheme = mapLegacyTheme(branding);
  Object.entries(mappedTheme).forEach(([property, value]) => {
    if (property === 'name') return;
    if (value) {
      document.documentElement.style.setProperty(property, value?.toString());
    }
  });
  const headingFont =
    branding?.fonts?.heading && branding?.fonts?.heading !== 'Roboto'
      ? `${branding?.fonts?.heading}:300,400,500,700`
      : null;
  const bodyFont =
    branding?.fonts?.body && branding?.fonts?.body !== 'Roboto'
      ? `${branding?.fonts?.body}:300,400,500,700`
      : null;
  // @ts-expect-error -- 'WebFont' is a global variable
  if (typeof WebFont !== 'undefined') {
    // @ts-expect-error -- 'WebFont' is a global variable
    WebFont.load({
      google: {
        families: ['Roboto:300,400,500,700', headingFont, bodyFont].filter(
          Boolean,
        ), // Remove falsy values from array
      },
    });
  }
};
