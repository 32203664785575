/* eslint @typescript-eslint/no-unsafe-member-access: "off", no-warning-comments: "off" */
import { ApiError } from '../payflow-client';

interface DetailError {
  loc: string[];
  msg: string;
  type: string;
}

export function apiErrorToMap(detail: string | DetailError[]) {
  if (typeof detail === 'string') {
    return { '': detail };
  }

  return detail.reduce((acc, error) => {
    const key = error?.loc?.join('.');
    return {
      ...acc,
      [key]: error?.msg,
    };
  }, {});
}

export function apiErrorToArray(detail: string | DetailError[]) {
  if (typeof detail === 'string') {
    return [detail];
  }

  return detail?.map(error => error?.msg);
}

export default function apiErrorToText(error: unknown): string {
  if (error instanceof ApiError) {
    // XXX ApiError means that the API has returned a non-2xx response, but we shouldn't always count
    // on the API to return a valid error response.
    if (error?.body?.detail) {
      const detail = error?.body?.detail as string;
      return apiErrorToArray(detail).join('\n');
    }
    if (error?.message) {
      return error?.message;
    }
  } else if (typeof error === 'string') {
    return error;
  }
  // @ts-expect-error -- optional chaining makes sure this doesn' crash
  error?.status !== 404 && console.error(error);
  return 'Unknown server error';
}
