import React, { FC } from 'react';

import { OpenAPI } from '../../payflow-client';

type BrandLogoProps = {
  logo_url?: string;
};

export const BrandLogo: FC<BrandLogoProps> = ({ logo_url }) => {
  if (!logo_url) {
    return null;
  }

  let src: string | undefined = logo_url;
  if (!(logo_url.startsWith("http://") || logo_url.startsWith("https://"))) {
    src = `${OpenAPI.BASE}/filestore/${logo_url}`;
  }

  return (
    <div className="flex justify-center">
      <img
        src={src}
        alt="logo"
        className="max-h-40"
      />
    </div>
  )
}
