import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PayflowContext } from '../contexts/payflow-context';
import { Page } from '../components/Page';
import { PaymentDetails } from '../sections/PaymentDetails';

export const CancelledPage: FC = () => {
  const { PaymentService } = useContext(PayflowContext);
  const { t } = useTranslation();
  return (
    <Page
      id="cancelled"
      title={t('Cancelled')}
      queryFn={PaymentService ? PaymentService.get : null}
    >
      {page =>
        page?.data && 'payment' in page.data ? (
          <PaymentDetails data={page?.data} />
        ) : null
      }
    </Page>
  );
};
