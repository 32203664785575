import React, { FC, FormEvent, useState } from 'react';
import { useLocation } from 'wouter';
import { useTranslation } from 'react-i18next';

import { PayflowGetResponse } from '../payflow-client';
import { BrandCard } from '../components/BrandCard';
import { TextField } from '../components/inputs/TextField';
import Spinner from '../components/Spinner';

function wait(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('resolved');
    }, milliseconds);
  });
}

type PaymentIntegrationProps = {
  payflow: PayflowGetResponse | null | undefined;
};

export const TestDummyCard: FC<PaymentIntegrationProps> = ({ payflow }) => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const [showIsPayingLoader, setShowIsPayingLoader] = useState<boolean>(false);

  const spanClasses = 'inline-block mb-2 text-base font-medium';
  const inputClasses =
    'w-full h-14 text-lg border border-gray-600 bg-gray-100 rounded px-3';

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const cardNumber = formData.get('cc-card') as string;
    const expiryDate = formData.get('expiry-date') as string;
    const cvc = formData.get('cvc') as string;

    setShowIsPayingLoader(true);

    await wait(3000);

    setShowIsPayingLoader(false);

    setLocation(`/${payflow?.id}/receipt`);
  };

  return (
    <BrandCard>
      {showIsPayingLoader && (
        <>
          <div className="flex items-center justify-center text-3xl">
            {t("Paying...")}
          </div>
          <div className="flex items-center justify-center py-6">
            <Spinner />
          </div>
        </>
      )}
      {!showIsPayingLoader && (
        <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
          <label htmlFor="cc-number">
            <span className={spanClasses}>{t('Card number')}</span>
            <TextField
              className="flex-auto"
              type="tel"
              name="cc-number"
              id="cc-number"
              placeholder={t("Card number")}
              autoComplete='cc-number'
            />
          </label>
          <div className="flex space-x-4">
            <label htmlFor="expiry-date">
              <span className={spanClasses}>{t('Expiry date')}</span>
              <TextField
                className="flex-auto"
                type="tel"
                id="expiry-date"
                name="expiry-date"
                placeholder="MM/YY"
                autoComplete="cc-exp"
              />
            </label>
            <label htmlFor="cvc">
              <span className={spanClasses}>{t('Security code')}</span>
              <TextField
                className="flex-auto"
                type="tel"
                id="cvc"
                name="cvc"
                placeholder="CVC"
                autoComplete="cc-csc"
              />
            </label>
          </div>
          <div className={spanClasses}>
            {t('Note: As this is a test payment, you can use any card number, of any length, and any expiry date.')}
          </div>
          <button
            type="submit"
            className="w-full h-14 font-medium text-lg btn-primary"
          >
            {t('Pay')}&nbsp;
            {((payflow?.payment?.gross_amount as number) / 100).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )}
          </button>
        </form>
      )}
    </BrandCard>
  );
};
