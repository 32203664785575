import React, { FC, ReactNode } from 'react';

type PillProps = {
  children: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error' | 'dark';
};

const colors = {
  info: {
    bg: 'bg-blue-50',
    text: 'text-blue-700',
    border: 'ring-blue-700/10',
  },
  success: {
    bg: 'bg-green-50',
    text: 'text-green-700',
    border: 'ring-green-600/10',
  },
  warning: {
    bg: 'bg-yellow-50',
    text: 'text-yellow-800',
    border: 'ring-yellow-600/10',
  },
  error: {
    bg: 'bg-red-50',
    text: 'text-red-700',
    border: 'ring-red-600/10',
  },
  dark: {
    bg: 'bg-gray-50',
    text: 'text-gray-600',
    border: 'ring-gray-500/10',
  },
};

export const Pill: FC<PillProps> = ({ children, variant = 'info' }) => {
  return <span className={colors[variant].bg + ` ` + colors[variant].border + ' ' + colors[variant].text + ' inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset'}>
    {children}
  </span>;
};
