/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PaymentStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  SENT = 'SENT',
  OPENED = 'OPENED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}
