import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'wouter';

import {
  OpenAPI,
  PayflowGetResponse,
  PaymentStatus,
  RefundStatusEnum,
  PayflowAttachment,
  PayflowPaymentCostLine,
  PayflowAttachmentRel,
  PaymentService,
  PayflowProfileFrequency,
  PaymentCostLineType,
} from '../../payflow-client';
import { BrandCard } from '../../components/BrandCard';
import { ArrowDownTray } from '../../components/icons/ArrowDownTray';
import {
  dateFormat,
  currencyFormat,
  percentFormat,
} from '../../utils/locale-utils';

import MarkdownWrapper from '../../components/MarkdownWrapper';
import { useQuery } from '@tanstack/react-query';

type PaymentDetailsProps = {
  data: PayflowGetResponse;
};

export const PaymentDetails: FC<PaymentDetailsProps> = ({ data }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [matchSplitPaymentRoute] = useRoute('/:payment_id/split-payment');
  const [matchReceiptRoute] = useRoute('/:payment_id/receipt');

  const isRefunded = data?.refund?.status !== RefundStatusEnum.NOT_REFUNDED;
  const isCancelled = data?.status === PaymentStatus.CANCELLED;
  const isPending = data?.status === PaymentStatus.PENDING;
  const isPaid = !isRefunded && data?.status === PaymentStatus.PAID;
  const isOpen = !isRefunded && !isCancelled && !isPending && !isPaid;

  const todayDate = new Date();
  const dueDate = new Date(data?.payment?.due_date as string);
  const paidDate = data?.paid_at ? new Date(data?.paid_at as string) : null;
  const pastDueDate = new Date(dueDate);
  pastDueDate.setDate(pastDueDate.getDate() + 1);

  const isOverdue =
    isOpen && (paidDate ? paidDate > pastDueDate : pastDueDate < todayDate);

  const mapRefundStatusToText = {
    PENDING: t('Refund is registered'),
    PARTIAL: t('Payment is partially refunded'),
    FULL: t('Payment is refunded'),
    FAILED: t('Refund failed'),
  } as typeof RefundStatusEnum;

  const parentPaymentQuery = useQuery({
    queryKey: ['payment', data?.parent_payflow_id],
    queryFn: () => PaymentService.get(data?.parent_payflow_id as string),
    enabled: Boolean(data?.parent_payflow_id),
  });

  let lines = Boolean(data?.parent_payflow_id)
    ? parentPaymentQuery.data?.payment?.lines
    : data?.payment?.lines;

  if (
    parentPaymentQuery.data?.payment?.customer_id === data?.payment?.customer_id
  ) {
    if (isPaid || isPending || isRefunded || isCancelled) {
      document.location.href = `/${data?.parent_payflow_id}`;
    }
  }

  const paidSoFar =
    data?.split_payment?.split
      ?.filter((split: any) => split.status == PaymentStatus.PAID)
      .reduce((acc: number, split: any) => acc + split.total, 0) || 0;

  const mapHeading = () => {
    let heading: string | null = null;
    if (matchSplitPaymentRoute) {
      heading = t('Total to pay');
    } else if (isOpen) {
      heading = t('Your payment');
    } else if (isPaid) {
      heading = t('Thank you!');
    } else if (isPending) {
      heading = t('Payment is pending');
    } else if (isRefunded) {
      heading = mapRefundStatusToText[data?.refund?.status as RefundStatusEnum];
    } else if (isCancelled) {
      heading = t('Payment is cancelled');
    }
    return heading;
  };

  const mapDueDate = () => {
    if (data?.payment?.due_date) {
      if (isOpen) {
        return (
          <div className="flex flex-wrap justify-between">
            <span>{t('Due date')}</span>
            <span className={`${isOverdue ? 'text-red-500' : undefined}`}>
              {dateFormat(data?.payment?.due_date, data?.merchant_country)}
              {isOverdue ? ` (${t('Overdue')})` : null}
            </span>
          </div>
        );
      } else if (isCancelled) {
        return (
          <div className="flex flex-wrap justify-between line-through">
            <span>{t('Due date')}</span>
            <span>
              {dateFormat(data?.payment?.due_date, data?.merchant_country)}
            </span>
          </div>
        );
      } else if (data?.paid_at) {
        return (
          <div className="flex flex-wrap justify-between">
            <span>{t('Paid date')}</span>
            <span>{dateFormat(data?.paid_at, data?.merchant_country)}</span>
          </div>
        );
      }
    }
    return null;
  };

  const showPartPaymentDetails = () => {
    return (
      <div className="flex flex-wrap justify-between py-4">
        {t(
          'This payment is part of a larger payment. The full original amount is {{totalAmount}}. You are only paying part of this.',
          {
            totalAmount: currencyFormat(
              parentPaymentQuery.data?.payment?.gross_amount,
              data?.merchant_country,
            ),
          },
        )}
      </div>
    );
  };

  return (
    <BrandCard heading={mapHeading()}>
      {Boolean(data?.parent_payflow_id) && showPartPaymentDetails()}
      {data?.payment?.vehicle ? (
        <div className="flex flex-wrap justify-between">
          {data?.payment?.vehicle?.car_model ? (
            <span>{data?.payment?.vehicle?.car_model}</span>
          ) : null}
          {data?.payment?.vehicle?.registration_number ? (
            <span>{data?.payment?.vehicle?.registration_number}</span>
          ) : null}
        </div>
      ) : null}
      {isPaid &&
        data?.selected_payment_option?.settings?.completed_explanation && (
          <MarkdownWrapper>
            {data?.selected_payment_option?.settings?.completed_explanation}
          </MarkdownWrapper>
        )}
      {mapDueDate()}
      {isPending &&
        data?.selected_payment_option?.settings?.pending_explanation && (
          <MarkdownWrapper>
            {data?.selected_payment_option?.settings?.pending_explanation}
          </MarkdownWrapper>
        )}
      {data?.payment?.invoice_comments ? (
        <p className={`my-1 ${isCancelled ? 'line-through' : ''}`}>
          {data?.payment?.invoice_comments}
        </p>
      ) : null}
      {showDetails ? (
        <>
          {lines ? (
            <>
              <div
                className={`divide-y divide-solid ${isCancelled ? 'line-through' : ''}`}
              >
                {lines?.map((line: PayflowPaymentCostLine, index: number) => (
                  <div
                    key={`${index}`}
                    className={
                      line.line_type == PaymentCostLineType.PART_SUM
                        ? 'flex justify-between py-2 text-sm font-italic font-bold'
                        : 'flex justify-between py-2'
                    }
                  >
                    <div>
                      <span
                        className={
                          line.line_type == PaymentCostLineType.PART_SUM
                            ? ''
                            : ''
                        }
                      >
                        {line?.description}
                      </span>
                      {line?.discount_rate === 1 || line?.quantity > 0 ? (
                        <div className="text-xs">
                          <span>
                            {currencyFormat(
                              line?.unit_price_excluding_vat,
                              data?.merchant_country,
                            )}{' '}
                            x{`${line?.quantity}, `}
                          </span>
                          <span>
                            {t('Sales tax')}: {currencyFormat(line?.total_vat)}
                          </span>
                          <span>{` (${percentFormat(line?.vat_rate, data?.merchant_country)})`}</span>
                          {Boolean(
                            line?.total_discount || line?.discount_rate,
                          ) ? (
                            <>
                              <span>{' - '}</span>
                              {line?.total_discount ? (
                                <span>{`${currencyFormat(line?.total_discount, data?.merchant_country)}`}</span>
                              ) : null}
                              {line?.discount_rate ? (
                                <span>{`${line?.total_discount ? ` (${percentFormat(line?.discount_rate, data?.merchant_country)})` : percentFormat(line?.discount_rate, data?.merchant_country)}`}</span>
                              ) : null}
                              <span> </span>
                              <span>{t('discount')}</span>
                            </>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {line?.line_type == PaymentCostLineType.PART_SUM &&
                    line?.unit_price_excluding_vat > 0 ? (
                      <span>
                        {currencyFormat(
                          line?.total_price_including_vat,
                          data?.merchant_country,
                        )}
                      </span>
                    ) : null}
                    {line?.line_type != PaymentCostLineType.PART_SUM &&
                    (line?.discount_rate === 1 || line?.quantity > 0) ? (
                      <span>
                        {currencyFormat(
                          line?.total_price_including_vat,
                          data?.merchant_country,
                        )}
                      </span>
                    ) : null}
                  </div>
                ))}
                <div className="py-2">
                  {paidSoFar > 0 && !data?.paid_at && (
                    <div className="flex flex-wrap justify-between">
                      <span>{t('Paid so far')}</span>
                      <span>
                        {currencyFormat(paidSoFar, data?.merchant_country)}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-wrap justify-between font-bold">
                    <span>
                      {data?.paid_at
                        ? t('Paid')
                        : paidSoFar > 0
                          ? t('Remaining to pay')
                          : t('To pay')}
                    </span>
                    <span>
                      {currencyFormat(
                        data?.paid_at ?
                          (data?.payment?.gross_amount || 0) :
                          (data?.payment?.gross_amount || 0) - paidSoFar,
                        data?.merchant_country,
                      )}
                    </span>
                  </div>
                  <div className="flex flex-wrap text-xs">
                    <span>
                      {currencyFormat(
                        data?.payment?.net_amount,
                        data?.merchant_country,
                      )}
                      , &nbsp;
                    </span>
                    <span>
                      {t('Sales tax')}:{' '}
                      {currencyFormat(
                        data?.payment?.vat_amount,
                        data?.merchant_country,
                      )}
                    </span>
                    {data?.payment?.discount_amount ? (
                      <span>
                        , {t('Discount')}:{' '}
                        {currencyFormat(
                          data?.payment?.discount_amount,
                          data?.merchant_country,
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {(data?.attachments as PayflowAttachment[]).length > 0 && !matchReceiptRoute ? (
                <>
                  <h4 className="text-center my-1">{t('Documents')}</h4>
                  <ul className="flex flex-col space-y-4 pb-4">
                    {data?.attachments?.map((attachment, index: number) => {
                      if (
                        !matchReceiptRoute &&
                        attachment?.rel ===
                          PayflowAttachmentRel.INVOICEAI_DOCUMENT
                      ) {
                        return null;
                      }
                      return (
                        <li key={attachment?.file_id}>
                          <a
                            href={`${OpenAPI.BASE}/filestore/${attachment?.file_id}.${attachment?.mime_type?.split('/')[1]}?download=1`}
                            className="flex flex-nowrap truncate h-12 p-3 btn-secondary"
                            download
                          >
                            <span className="grow h-full mx-2 truncate">{`${index + 1}. ${attachment?.filename}`}</span>
                            <span className="grow-0 w-6">
                              <ArrowDownTray />
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : null}
              <div className="text-center pt-2">
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    setShowDetails(false);
                  }}
                  className="underline text-primary"
                >
                  {t('Hide details')}
                </a>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div className="flex justify-between items-end">
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                setShowDetails(true);
              }}
              className="underline text-primary"
            >
              {t('View more details')}
            </a>
            <span
              className={`text-2xl text-primary mt-4 ${isCancelled ? 'line-through' : ''}`}
            >
              {data?.profile_frequency === PayflowProfileFrequency.RECURRING && data?.payment.recurring?.enabled ? (
                <>
                  {currencyFormat(
                    (data?.payment?.gross_amount || 0) /
                      (data?.payment?.recurring?.number_of_charges || 1),
                    data?.merchant_country,
                    data?.currency,
                  )}{' '}
                  {t('per month')}
                </>
              ) : (
                currencyFormat(
                  data?.payment?.gross_amount,
                  data?.merchant_country,
                  data?.currency,
                )
              )}
            </span>
          </div>
          {data?.profile_frequency === PayflowProfileFrequency.RECURRING && data?.payment.recurring?.enabled && (
            <div className="py-2">
              <div className="flex flex-wrap justify-between text-xs">
                <span>
                  {t(
                    'Total to pay is {{gross_amount}} paid over {{months}} months.',
                    {
                      gross_amount: currencyFormat(
                        data?.payment?.gross_amount,
                        data?.merchant_country,
                        data?.currency,
                      ),
                      months: data?.payment?.recurring?.number_of_charges,
                    },
                  )}
                </span>
              </div>
            </div>
          )}
          {paidSoFar > 0 && !data?.paid_at && (
            <div className="py-2">
              <div className="flex flex-wrap justify-between text-xs">
                <span>{t('Paid so far')}</span>
                <span>
                  {currencyFormat(
                    paidSoFar,
                    data?.merchant_country,
                    data?.currency,
                  )}
                </span>
              </div>
              <div className="flex flex-wrap justify-between text-xs font-bold">
                <span>{t('Remaining to pay')}</span>
                <span>
                  {currencyFormat(
                    (data?.payment?.gross_amount || 0) - paidSoFar,
                    data?.merchant_country,
                    data?.currency,
                  )}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </BrandCard>
  );
};
