import React, { FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import apiErrorToText from '../utils/api-errors-parser';
import {
  ApiError,
  PayflowGetResponse,
  PayflowSelectPaymentOption,
  EFakturaService,
} from '../payflow-client';
import { BrandCard } from '../components/BrandCard';
import Spinner from '../components/Spinner';
import { AlertDialog } from '../components/AlertDialog';
import { TextField } from '../components/inputs/TextField';
import { EFakturaFunnelStatus } from '../payflow-client';

type EFakturaProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const EFaktura: FC<EFakturaProps> = ({ payflow, payment_option }) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [phone_number, setPhoneNumber] = React.useState<string>('');

  const [eFakturaStatus, setEFakturaStatus] = React.useState<EFakturaFunnelStatus>(
    payment_option?.script_additional_data?.status,
  );

  const spanClasses = 'inline-block mb-2 text-base font-medium';

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await EFakturaService.initiate(payflow?.id as string, {
        email, phone_number
      })
      setEFakturaStatus(response)
    } catch (error) {
      if (error instanceof ApiError) {
        setError(apiErrorToText(error));
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <BrandCard>
      <AlertDialog
        open={Boolean(error)}
        onClose={() => setError(null)}
        heading={t('Error')}
        content={error}
        variant="error"
      />
      {eFakturaStatus === EFakturaFunnelStatus.STARTED  && (
        <form className="flex flex-col gap-y-3.5" onSubmit={handleSubmit}>
          <label htmlFor="email">
            <span className={spanClasses}>{t('Email')}</span>
            <TextField
              type="email"
              id="email"
              name="email"
              placeholder={t('Email')}
              autoComplete="email"
              required
              onChange={e => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="phone_number">
            <span className={spanClasses}>{t('Phone number')}</span>
            <TextField
              type="text"
              id="phone_number"
              name="phone_number"
              placeholder={t('Phone number')}
              required
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </label>
          {isLoading && (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          )}
          <button
            type="submit"
            className="w-full h-14 font-medium text-lg btn-primary"
            disabled={
              isLoading
            }
          >
            {t('Next')}
          </button>
        </form>
      )}
      {eFakturaStatus === EFakturaFunnelStatus.INVOICE_ADDED && (
        <div className="flex items-center justify-center">
          <p>{t('Efaktura invoice is pending.')}</p>
        </div>
      )}
      {[EFakturaFunnelStatus.CONSIGNMENT_PROCESSED, EFakturaFunnelStatus.INVOICE_ADDED, EFakturaFunnelStatus.PROCESSED].includes(eFakturaStatus) && (
        <div className="flex items-center justify-center">
          <p>{t('Efaktura invoice sent.')}</p>
        </div>
      )}
      {[EFakturaFunnelStatus.INVOICE_FAILED, EFakturaFunnelStatus.CONSIGNMENT_FAILED].includes(eFakturaStatus) && (
        <div className="flex items-center justify-center">
          <p>{t('Efaktura invoice failed.')}</p>
        </div>
      )}
    </BrandCard>
  )
}