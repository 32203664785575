import React, { FC } from 'react';

import {
  PayflowGetResponse,
  PayflowSelectPaymentOption,
} from '../payflow-client';
import { BrandCard } from '../components/BrandCard';
import { mapSwedbankSupportedLocales } from '../utils/integrations/swedbank/localse';

type SwedbankCheckoutProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const SwedbankCheckout: FC<SwedbankCheckoutProps> = ({
  payflow,
  payment_option,
}) => {
  const containerId = 'iframe-container-div';
  const scriptElement = document.getElementsByClassName(
    'swedbank-checkout-script',
  );

  if (scriptElement.length === 0) {
    const script = document.createElement('script');
    script.setAttribute('class', 'swedbank-checkout-script');
    script.setAttribute(
      'src',
      payment_option?.script_additional_data?.url as string,
    );
    script.setAttribute('type', 'text/javascript');
    script.onload = () => {
      // @ts-expect-error -- 'payex' is a global variable
      payex.hostedView
        .checkout({
          container: { checkout: containerId },
          culture: mapSwedbankSupportedLocales.get(
            payflow?.merchant_country || 'en',
          ),
        })
        .open();
    };
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  return (
    <BrandCard>
      <div id={containerId}></div>
    </BrandCard>
  );
};
