import React, { FC } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { PayflowProfileBrandingLocation } from '../../payflow-client';

import GOOGLE_API_KEY from '../../google_api_key';

export const GoogleMaps: FC<PayflowProfileBrandingLocation> = ({
  latitude,
  longitude,
  zoom,
  show_on_receipt,
}) => {
  if (!show_on_receipt) return null;
  if (!GOOGLE_API_KEY) {
    console.warn(
      'Google API key is missing. The map will not render without this API key.',
    );
    return null;
  }
  const position = { lat: latitude as number, lng: longitude as number };
  return (
    <div className="block h-96 mt-6">
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <Map defaultCenter={position} defaultZoom={zoom}>
          <Marker position={position} />
        </Map>
      </APIProvider>
    </div>
  );
};
