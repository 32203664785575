import React, { FC, ReactNode } from 'react';

type BrandButtonType = {
  children: ReactNode;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
};

export const BrandButton: FC<BrandButtonType> = ({
  children,
  onClick,
  variant = 'secondary',
  disabled,
  className,
}) => {
  let classNames = `flex flex-nowrap truncate h-12 p-3 btn-${variant}`;
  if (disabled) classNames += ' opacity-50';

  if (className?.includes('h-8')) {
    classNames = classNames.replace('h-12', 'h-8');
  }
  if (className?.includes('p-1')) {
    classNames = classNames.replace('p-3', 'p-1');
  }

  return (
    <button
      className={
        classNames + (className ? ` ${className}` : '')
      }
      onClick={onClick}
      disabled={disabled}
    >
      <span className="grow h-full mx-2 truncate">{children}</span>
    </button>
  );
};
