import React, { FC } from 'react';
import { useLocation } from 'wouter';

import {
  PayflowGetResponse,
  PayflowSelectPaymentOption,
} from '../payflow-client';
import { TDibs } from '../types/Dibs';
import { BrandCard } from '../components/BrandCard';
import { mapDibsSupportedLocales } from '../utils/integrations/nexi/locales';

const NETS_LIVE_ENV = 'https://checkout.dibspayment.eu/v1/checkout.js?v=1';
const NETS_TEST_ENV = 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1';

type NetsEasyProps = {
  payflow: PayflowGetResponse | null | undefined;
  payment_option: PayflowSelectPaymentOption | null | undefined;
};

export const NetsEasy: FC<NetsEasyProps> = ({ payflow, payment_option }) => {
  const [, setLocation] = useLocation();
  const containerId = 'iframe-container-div';
  const scriptElement = document.getElementsByClassName('nets-easy-script');

  if (scriptElement.length === 0) {
    const script = document.createElement('script');
    script.setAttribute('class', 'nets-easy-script');
    script.setAttribute(
      'src',
      payflow?.test_mode ? NETS_TEST_ENV : NETS_LIVE_ENV,
    );
    script.setAttribute('type', 'text/javascript');
    script.onload = () => {
      // @ts-expect-error -- 'Dibs' is a global variable
      const checkout: TDibs.Checkout = new Dibs.Checkout({
        paymentId: payment_option?.script_additional_data?.payment_id,
        checkoutKey: payment_option?.script_additional_data?.checkout_key,
        containerId,
        language: mapDibsSupportedLocales.get(
          payflow?.merchant_country || 'en',
        ),
      }) as TDibs.Checkout;
      checkout.on('payment-completed', () => {
        setLocation(`/${payflow?.id}/receipt`);
      });
    };
    document.body.appendChild(script);
  }

  return (
    <BrandCard>
      <div id={containerId}></div>
    </BrandCard>
  );
};
