import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboxContent } from '../sections/Keybox/KeyboxContent';
import { PaymentService } from '../payflow-client';

type ManualPinCodeProps = {
  data: any;
  keybox: any;
};

export const ManualPinCode: FC<ManualPinCodeProps> = ({ data, keybox }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string | null>(null);

  const fetchPinCode = async () => {
    try {
      const response = await PaymentService.openKeybox(data?.id, {});
      setCode(response?.pincode);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getGridColStyles = (code: string) => {
    if (code.length > 6) {
      return `grid-flow-dense grid-cols-${Math.ceil(code.length / 2)} gap-y-6`;
    }
    return `grid-flow-col-dense grid-cols-${code.length}`;
  };

  return (
    <KeyboxContent keybox={keybox}>
      {code && (
        <div
          className={`grid gap-2 sm:gap-4 justify-items-center ${getGridColStyles(code)}`}
        >
          {code.split('').map((char, index) => (
            <div
              key={`${index}_${char}`}
              className="rounded-md shadow-2xl shadow-inner text-center bg-slate-100 text-slate-500 font-bold text-sm px-3 py-2 w-8 sm:w-14 sm:text-3xl "
            >
              {char}
            </div>
          ))}
        </div>
      )}
      {!code && (
        <button
          onClick={fetchPinCode}
          className="w-full h-14 font-medium text-lg btn-primary mt-4"
        >
          {t('Get Pin-code')}
        </button>
      )}
    </KeyboxContent>
  );
};
